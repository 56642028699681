<template>
<div class="slider">
  <!-- eslint-disable -->
  <component :is="'style'">
    .slider-input::-webkit-slider-runnable-track {
      {{ sliderBackground }}
    }
    .slider-input::-moz-range-track {
      {{ sliderBackground }}
    }
    .slider-input::-ms-track {
      {{ sliderBackground }}
    }
  </component>
  <!-- eslint-enable -->
  <input
    class="slider-input"
    type="range"
    :value="slideValue"
    :min="min"
    :max="max"
    @input="handleInput"
    @mouseleave="handleInput"
  >
</div>
</template>

<script>
export default {
  name: 'p-slider',
  props: {
    slideValue: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    lowerLimit: {
      type: Number,
      default: null,
    },
    upperLimit: {
      type: Number,
      default: null,
    },
  },
  computed: {
    sliderBackground() {
      const val = this.slideValue;
      return `background: linear-gradient(to right, #2e40ea ${val}%, #c8c8cb ${val}%, #c8c8cb 100%);`;
    },
  },
  methods: {
    disableAtLimits(ev, limit) {
      ev.preventDefault();
      ev.target.value = limit;
      this.$emit('input', limit);
    },
    handleInput(ev) {
      const val = ev.target.value;
      const { lowerLimit, upperLimit } = this;

      if(lowerLimit && parseInt(val) < lowerLimit) {
        this.disableAtLimits(ev, lowerLimit);
      } else if(upperLimit && parseInt(val) > upperLimit) {
        this.disableAtLimits(ev, upperLimit);
      } else {
        this.$emit('input', val);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.slider {
  width: 100%;
  margin: 20px 0;
}
.slider-input {
  -webkit-appearance: none;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  border-color: $border-light;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;

  @mixin sliderThumb {
    width: 20px;
    height: 20px;
    margin: -9px 0 0;
    border-radius: 50%;
    background: $main-blue;
    cursor: pointer;
    border: 0 !important;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include sliderThumb;
  }
  &::-moz-range-thumb {
    @include sliderThumb;
  }
  &::-ms-thumb {
    @include sliderThumb;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
  }
  &::-moz-range-track {
    width: 100%;
    height: 2px;
  }
  &::-ms-track {
    width: 100%;
    height: 2px;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}
</style>>
