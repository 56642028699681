
export default {
  data() {
    return {
      dragging: false,
    };
  },
  methods: {
    handleFileSelect(e) {
      if(e.type === 'input') {
        const file = e.target.files[0];
        this.$emit('file-select', file);
      } else if(e.type === 'drop') {
        const file = e.dataTransfer.files[0];
        this.$emit('file-select', file);
      }
      this.dragging = false;
    },
    dragStart(e) {
      e.preventDefault();
      this.dragging = true;
    },
    dragEnd(e) {
      e.preventDefault();
      this.dragging = false;
    },
    dropUploadImage(e) {
      e.preventDefault();
      this.handleFileSelect(e);
    },
  },
};
