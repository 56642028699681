<template>
<div v-if="dataValue" class="basics-wrap">
  <DashboardHeader
    :tab="$t('dashboard.basics.title')"
    :saveStatus="saveStatus"
    :previewCode="dataValue.preview_code"
    :crowdfunding="dataValue.crowdfunding"
  />
  <div class="container">
    <div class="dashboard-content">
      <h1>{{ $t('dashboard.basics.title') }}</h1>
      <Section :info="$t('dashboard.basics.project_name')">
        <TitledInput
          v-model="dataValue.name"
          name="project-title"
          :placeholder="$t('dashboard.basics.project_name.title')"
          :errorMessage="errors.name"
          :errorSubMessage="errors.nameSub"
          @input="updated('name')"
        />
      </Section>
      <Section :info="$t('dashboard.basics.blurb')">
        <TitledInput
          v-model="dataValue.blurb"
          name="project-blurb"
          :placeholder="$t('dashboard.basics.blurb.title')"
          :rows="3"
          :errorMessage="errors.blurb"
          :errorSubMessage="errors.blurbSub"
          @input="updated('blurb')"
        />
      </Section>
      <Section :info="$t('dashboard.basics.category')" class="category">
        <TitledSelect
          v-model="dataValue.category"
          :options="$store.getters.categories"
          label="name"
          trackBy="id"
          @input="updated('category')"
        />
      </Section>
      <Section :info="$t('dashboard.basics.location')">
        <TitledInput
          v-model="dataValue.location"
          name="project-location"
          :placeholder="$t('dashboard.basics.location.placeholder')"
          @input="updated('location')"
        />
      </Section>
      <Section :wrap="true" :info="$t('dashboard.basics.main_image')">
        <ImageUpload
          id="upload-main"
          class="upload-main"
          :backgroundImage="dataValue.main_image.url"
          :loading="imageLoading === MAIN"
          @file-select="validateImage(mainType, $event, MAIN)"
        />
        <ErrorList
          v-if="imageErrorType === MAIN"
          :errors="imageUploadErrors"
          class="upload-main-errors"
        />
      </Section>
      <Section :info="$t('dashboard.basics.main_video')">
        <TitledInput
          v-model="dataValue.main_video"
          name="project-video"
          :placeholder="$t('dashboard.basics.main_video.placeholder')"
          :errorMessage="errors.main_video"
          @input="updated('main_video')"
        />
      </Section>
      <Section class="carousel-section" :info="$t('dashboard.basics.carousel')" />
      <div class="carousel-images">
        <div v-if="dataValue.images && dataValue.images.length">
          <div class="carousel-images-info">
            <div>
              {{ $tc('dashboard.basics.carousel.uploaded', dataValue.images.length) }}
            </div>
            <FileUploadButton
              id="carousel-file-upload"
              :text="$t('dashboard.basics.carousel.browse')"
              :loading="imageLoading === CAROUSEL"
              @file-select="validateImage(carouselType, $event, CAROUSEL)"
            />
          </div>
          <ImageList
            :images="dataValue.images"
            :projectId="dataValue.id"
            :loading="imageLoading === CAROUSEL"
            @on-delete="carouselImageDeleted"
            @on-order="update('images', $event)"
            @file-select="validateImage(carouselType, $event, CAROUSEL)"
          />
        </div>
        <ImageUpload
          v-else
          id="upload-project"
          :loading="imageLoading === CAROUSEL"
          @file-select="validateImage(carouselType, $event, CAROUSEL)"
        />
        <ErrorList v-if="imageErrorType === CAROUSEL" :errors="imageUploadErrors" />
      </div>
      <Section class="social-right" :wrap="true" :info="$t('dashboard.basics.social')">
        <ImageUpload
          id="upload-social"
          :backgroundImage="dataValue.social_media_image.url"
          :loading="imageLoading === SOCIAL"
          @file-select="validateImage(socialType, $event, SOCIAL)"
        />
        <ErrorList v-if="imageErrorType === SOCIAL" :errors="imageUploadErrors" />
      </Section>
      <Section :info="$t('dashboard.basics.goal')">
        <TitledInput
          :value="fundingGoal"
          type="number"
          step="1"
          name="project-goal"
          :hasIcon="true"
          :errorMessage="errors.funding_goal"
          @input="update('funding_goal', $event && $event * 100)"
        />
      </Section>
      <Section :info="$t('dashboard.basics.currency')" class="currency">
        <TitledSelect
          :options="currencies"
          :value="currency"
          @input="updateCurrency"
        />
      </Section>
      <Section :info="$t('dashboard.basics.length')">
        <TitledInput
          v-model="dataValue.duration"
          name="project-length"
          type="number"
          step="1"
          :placeholder="$t('dashboard.basics.length.placeholder')"
          :errorMessage="errors.duration"
          :errorSubMessage="errors.durationSub"
          @input="updated('duration')"
        />
      </Section>
      <!-- TODO: re-enable once payments are working
      <Section :info="$t('dashboard.basics.referral')">
        <div class="referral">
          <Checkbox
            :item="{
              checked: dataValue.referrals_enabled,
              label: $t('dashboard.basics.referral.check'),
            }"
            :onCheck="onReferralCheck"
          />
        </div>
      </Section>
      -->
      <Section :info="$t('dashboard.basics.analytics')">
        <TitledInput
          v-model="dataValue.google_analytics_id"
          name="project-analytics"
          :placeholder="$t('dashboard.basics.analytics.placeholder')"
          :errorMessage="errors.google_analytics_id"
          @input="updated('google_analytics_id')"
        />
      </Section>
    </div>
  </div>
  <DashboardFooter :label="$t('dashboard.overview.title')" link="dashboard-overview" />
  <Drawer
    v-if="drawerInfo"
    :info="drawerInfo"
    @hide="selectedDrawer = null"
  />
</div>
</template>

<script>
import DashboardHeader from '@/components/builder/DashboardHeader';
import DashboardFooter from '@/components/builder/DashboardFooter';
import ImageList from '@/components/builder/ImageList';
// import Checkbox from '@/components/widget/Checkbox';
import ErrorList from '@/components/widget/ErrorList';
import ImageUpload from '@/components/widget/ImageUpload';
import FileUploadButton from '@/components/widget/FileUploadButton';
import Section from '@/components/widget/Section';
import TitledInput from '@/components/widget/TitledInput';
import AutoSaveProject from '@/mixins/AutoSaveProject';
import { Currencies } from '@/utils/constants';
import TitledSelect from '@/components/widget/TitledSelect';
import { validateYouTubeUrl, validateLength, validateRange, validateGAnalytics } from '@/utils/stringUtils';
import { validateImage as validateImageUtil } from '@/utils/objectUtils';
import { ImageType, uploadProjectImage } from '@/utils/api';
import { errorKeys } from '@/utils/apiError';
import Drawer from '@/components/widget/Drawer';

export default {
  name: 'dashboard-basics',
  components: {
    Section,
    DashboardHeader,
    DashboardFooter,
    // Checkbox,
    ErrorList,
    ImageList,
    ImageUpload,
    FileUploadButton,
    TitledInput,
    TitledSelect,
    Drawer,
  },
  mixins: [AutoSaveProject],
  props: {
    formName: {
      type: String,
      default: 'project_dashboard',
    },
  },
  data() {
    return {
      rules: {
        main_video: {
          validator: validateYouTubeUrl,
        },
        funding_goal: {
          required: true,
          validator: Number.isInteger,
        },
        name: {
          required: true,
          validator: val => validateLength(val, { minLength: 2, maxLength: 128 }),
          subError: { key: 'errors.suberror_char_range', data: { min: 2, max: 128 } },
        },
        blurb: {
          required: true,
          validator: val => validateLength(val, { minLength: 2, maxLength: 256 }),
          subError: { key: 'errors.suberror_char_range', data: { min: 2, max: 256 } },
        },
        duration: {
          required: true,
          validator: val => validateRange(val, { min: 15, max: 60 }),
          subError: { key: 'errors.suberror_range', data: { min: 15, max: 60 } },
        },
        google_analytics_id: {
          validator: validateGAnalytics,
        },
      },
      mainType: { width: 900, height: 1200, ext: ['jpg', 'png'], size: 2000000 },
      carouselType: { width: 1024, height: 576, ext: ['jpg', 'png'], size: 2000000 },
      socialType: { width: 600, height: 314, ext: ['jpg', 'png'], size: 1000000 },
      ...ImageType,
      imageErrorType: null,
      imageUploadErrors: null,
      imageLoading: null,
      selectedDrawer: '',
      showDrawer: false,
      currencies: Currencies,
    };
  },
  computed: {
    drawerInfo() {
      if(!this.selectedDrawer) {
        return null;
      }
      return this.$t(`dashboard.basics.${this.selectedDrawer}.drawer`);
    },
    fundingGoal() {
      if(Number.isNaN(this.dataValue.funding_goal)) {
        return '';
      }
      return this.dataValue.funding_goal / 100;
    },
    currency() {
      return this.dataValue.currency || this.currencies[0];
    },
  },
  methods: {
    updateCurrency(value) {
      this.dataValue.currency = value;
      this.updated('currency');
    },
    onReferralCheck(checked) {
      this.$set(this.dataValue, 'referrals_enabled', checked);
      this.updated('referrals_enabled');
    },
    async imageUpload(file, type) {
      this.imageErrorType = null;
      this.imageLoading = type;
      try {
        const { data } = await uploadProjectImage({
          projectId: this.dataValue.id,
          type,
          file,
        });
        this.imageLoading = null;
        return data.resource;
      } catch(error) {
        this.imageErrorType = type;
        if(error.errors) {
          this.imageUploadErrors = [...errorKeys(error.errors), this.imageUploadErrors];
        }
        this.imageLoading = null;
      }
      return null;
    },
    async imageSelected(file, fileType) {
      if(fileType === this.CAROUSEL) {
        if(this.dataValue.images.length < 10) {
          const image = await this.imageUpload(file, fileType);
          if(image) {
            const images = this.dataValue.images || [];
            this.dataValue.images = [...images, image];
          }
        } else {
          this.imageUploadErrors = ['MAX_COUNT'];
          this.imageErrorType = fileType;
        }
      } else {
        const image = await this.imageUpload(file, fileType);
        if(image) {
          this.dataValue[fileType] = image;
        }
      }
    },
    carouselImageDeleted({ images, errors }) {
      this.dataValue.images = images;
      if(errors) {
        this.imageErrorType = this.CAROUSEL;
        this.imageUploadErrors = errors;
      } else {
        this.imageErrorType = null;
      }
    },
    selectDrawer(name) {
      this.showDrawer = true;
      this.selectedDrawer = name;
    },
    validateImage(requirements, file, fileType) {
      validateImageUtil(requirements, file, (validFile, errors) => {
        if(errors) {
          this.imageUploadErrors = errors;
          this.imageErrorType = fileType;
        } else {
          this.imageSelected(validFile, fileType, errors);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.basics-wrap > .container {
  overflow: visible;
}
.dashboard-content {
  .img-upload-wrap {
    margin-left: auto;
    cursor: pointer;
    position: relative;
    &.upload-main {
      width: 300px;
      height: 400px;
    }
    #upload-social {
      height: 270px;
      width: 513px;
    }
  }
  .upload-main-errors {
    width: 300px;
    margin-left: auto;
  }
  #upload-project {
    height: 240px;
    max-width: 100%;
    margin-bottom: 48px;
  }
  .p-input-wrap {
    margin: 0;
    input {
      @include h4;
      font-weight: 600;
    }
    textarea {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  .carousel-section {
    padding-bottom: 16px;
  }
  .section-note span:not(:last-child) {
    padding-right: 24px;
  }
  .section-right {
    .uploaded-image { width: 100%; }
  }
  .carousel-images {
    padding-bottom: 24px;
  }
  .carousel-images-info {
    display: flex;
    justify-content: space-between;
  }
  .social-right .section-right {
    flex-shrink: 0;
  }

  .referral {
    width: 100%;
    height: 48px;
    border: 1px solid $border-light;
    display: flex;
    padding-left: 16px;
    align-items: center;
  }

  @media (max-width: $tablet-width-large) {
    .img-upload-wrap#upload-social {
      width: 380px;
      height: 200px;
    }
  }
  @media (max-width: $tablet-width) {
    .img-upload-wrap {
      &#upload-social, &#upload-main {
        width: 100%;
        height: 240px;
      }
    }
  }
  @media (max-width: $desktop-width-small) {
    .section-note span {
      padding: 8px 0 0 0;
      display: block;
    }
  }
}
</style>
