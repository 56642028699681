<template>
<div v-if="project" class="dashboard-rewards-wrap">
  <DashboardHeader
    :tab="$t(`${rewardsKey}.title`)"
    :previewCode="project.preview_code"
    :crowdfunding="project.crowdfunding"
  />
  <div class="container">
    <div class="dashboard-content">
      <h1>{{ $t(`${rewardsKey}.title`) }}</h1>
      <TabBar
        :tabs="$t(`dashboard.${rewardsKey}.tabs`)"
        :activeTab="activeTab"
        :onChangeTab="setTab"
      />
    </div>
  </div>
  <keep-alive>
    <component
      :is="activeComponent"
      :project="project"
      @refresh-project="getProjectData"
    />
  </keep-alive>
  <DashboardFooter :label="$t('dashboard.timeline.title')" link="dashboard-timeline" />
</div>
</template>

<script>
import * as api from '@/utils/api';
import DashboardHeader from '@/components/builder/DashboardHeader.vue';
import DashboardFooter from '@/components/builder/DashboardFooter.vue';
import Rewards from '@/components/builder/Rewards.vue';
import ShippingOptions from '@/components/builder/ShippingOptions.vue';
import TabBar from '@/components/widget/TabBar.vue';

export default {
  name: 'dashboard-rewards',
  components: {
    DashboardHeader,
    DashboardFooter,
    Rewards,
    ShippingOptions,
    TabBar,
  },
  data() {
    return {
      rewardsKey: 'rewards',
      activeTab: this.$t('dashboard.rewards.tabs')[0],
      activeComponent: 'Rewards',
      project: null,
      staleTimer: null,
      stale: new Set(),
    };
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab;
      if(tab.label === 'Shipping Options') {
        this.activeComponent = 'ShippingOptions';
      } else {
        this.activeComponent = 'Rewards';
      }
    },
    async getProjectData() {
      try {
        this.project = await api.getCreatorProjectDetail(this.$route.params.id);
      } catch(err) {
        console.log('Error getting project data: ', err);
      }
    },
  },
  async created() {
    await this.getProjectData();
    this.rewardsKey = this.project.crowdfunding ? 'rewards' : 'products';
    [this.activeTab] = this.$t(`dashboard.${this.rewardsKey}.tabs`);
  },
};

</script>

<style lang="scss">
@import 'general';

.dashboard-rewards-wrap {
  .tab-bar {
    border-bottom: 1px solid $border-light;
  }
}
</style>
