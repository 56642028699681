<template>
<div v-if="project" class="overview-wrap">
  <DashboardHeader
    :showTabs="false"
    :previewCode="project.preview_code"
  />
  <div class="dashboard-content container">
    <h1>{{ $t('dashboard.bonus.title') }}</h1>
    <hr>
    <div class="dashboard-bonus-content">
      <div class="dashboard-bonus-left">
        <div class="bonus-title">
          {{ $t('dashboard.bonus.available') }}
        </div>
        <div v-if="bonuses.length" class="bonus">
          <div class="bonus-new" @click="showQuestionModal({})">
            {{ $t('dashboard.bonus.new') }}
          </div>
          <draggable
            v-model="bonuses"
            class="dashboard-bonus-draggable"
            @end="updateOrder"
          >
            <FAQAccordion
              v-for="(bonusItem, index) in bonuses"
              :key="index"
              :value="bonusItem"
              :edit="true"
              @edit="showQuestionModal(bonusItem); $event.stopPropagation();"
              @delete="deleteQuestion(bonusItem); $event.stopPropagation();"
            />
          </draggable>
        </div>
        <div v-else class="bonus-empty">
          <div class="bonus-empty-text">
            {{ $t('dashboard.bonus.empty') }}
          </div>
          <div class="bonus-new" @click="showQuestionModal({})">
            {{ $t('dashboard.bonus.new') }}
          </div>
        </div>
      </div>
      <div class="dashboard-bonus-right">
        <div>
          {{ $t('dashboard.bonus.text1') }}
        </div>
        <div>
          {{ $t('dashboard.bonus.text2') }}
        </div>
      </div>
    </div>
  </div>
  <BonusModal
    v-if="showNewQuestion"
    :bonusItem="selectedQuestion"
    :projectId="project.id"
    :cancelText="$t('dashboard.bonus.discard')"
    @saved="questionUpdated"
    @close-modal="hideQuestionModal"
  />
</div>
</template>

<script>
import draggable from 'vuedraggable';
import * as api from '@/utils/api';
import { objectIdSort } from '@/utils';
import DashboardHeader from '@/components/builder/DashboardHeader';
import BonusModal from '@/components/builder/BonusModal';
// import FAQAccordion from '@/components/project/FAQAccordion';

export default {
  name: 'dashboard-bonus',
  components: {
    draggable,
    DashboardHeader,
    // FAQAccordion,
    BonusModal,
  },
  data() {
    return {
      project: null,
      showNewQuestion: false,
      selectedQuestion: null,
      bonuses: null,
    };
  },
  watch: {
    project(newProject) {
      const { bonuses, bonus_order: order } = newProject;
      this.bonuses = objectIdSort(bonuses || [], order);
    },
  },
  methods: {
    async deleteQuestion(item) {
      try {
        await api.deleteFAQItem({
          projectId: this.project.id,
          bonusItemId: item.id,
        });
        this.getProjectData();
      } catch(e) {
        console.log(e);
      }
    },
    questionUpdated() {
      this.hideQuestionModal();
      this.getProjectData();
    },
    hideQuestionModal() {
      this.selectedQuestion = null;
      this.showNewQuestion = false;
    },
    showQuestionModal(item) {
      this.selectedQuestion = item;
      this.showNewQuestion = true;
    },
    async getProjectData() {
      try {
        this.project = await api.getCreatorProjectDetail(this.$route.params.id);
      } catch(err) {
        console.log('Error getting project data: ', err);
      }
    },
    updateOrder(e) {
      if(e.oldIndex !== e.newIndex) {
        const projectId = this.project.id;
        const order = this.bonuses.map(bonus => bonus.id);
        api.updateProject({ projectId, projectData: { bonus_order: order } });
      }
    },
  },
  created() {
    this.getProjectData();
  },
};

</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.dashboard-content {
  margin-top: 72px;
  margin-bottom: 72px;
}
.dashboard-bonus-content {
  display: flex;
  flex-wrap: wrap;
  .bonus-new {
    @include button;
  }
  .bonus .bonus-new {
    margin: 16px 0;
  }
  .dashboard-bonus-left {
    width: 60%;
    padding-right: 40px;
    .bonus-title {
      @include h3;
    }
    .bonus-empty {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: $bg-grey-light;
      padding: 48px 32px;
      margin-top: 16px;
      .bonus-empty-text {
        @include h4;
        color: $grey50;
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
      }
    }
  }
  .dashboard-bonus-right {
    width: 40%;
    @include p_small;
    color: $black-med;
    > div:not(:first-child) {
      margin-top: 24px;
    }
  }
  @media (max-width: $tablet-width) {
    .dashboard-bonus-left, .dashboard-bonus-right {
      width: 100%;
    }
    .dashboard-bonus-right {
      padding-top: 24px;
    }
  }
}
</style>
