<template>
<Modal
  class="translation-add"
  :title="$t('dashboard.translation.add.title')"
  :submitText="$t('confirm')"
  @submit="confirmSelected"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="translation-modal-body">
      <div class="translation-modal-desc">
        {{ $t('dashboard.translation.add.desc') }}
      </div>
      <TitledSelect
        :options="languages"
        label="name"
        trackBy="code"
        :multiple="true"
        :closeOnSelect="false"
      >
        <template slot="option" slot-scope="props">
          <div
            class="option__desc"
            @click="$set(props.option, 'checked', !props.option.checked)"
          >
            <Checkbox :item="{ checked: props.option.checked }" />
            <span class="option__title">
              {{ props.option.name }}
            </span>
          </div>
        </template>
        <template slot="selection">
          <span class="multiselect__single">
            {{ customLabel }}
          </span>
        </template>
      </TitledSelect>
    </div>
  </template>
</Modal>
</template>

<script>

import Modal from '@/components/widget/Modal';
import TitledSelect from '@/components/widget/TitledSelect';
import Checkbox from '@/components/widget/Checkbox';

export default {
  name: 'add-translation-modal',
  components: {
    Modal,
    TitledSelect,
    Checkbox,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const codes = this.$t('dashboard.translation.languages');
    return {
      languages: Object.keys(codes).map(code => ({
        code,
        name: codes[code],
        checked: this.selected.includes(code),
      })),
    };
  },
  computed: {
    customLabel() {
      const selected = this.languages.filter(l => l.checked);
      if(selected.length) {
        return this.$tc('dashboard.translation.add.selected', selected.length);
      }
      return this.$t('dashboard.translation.add.select');
    },
  },
  methods: {
    async confirmSelected() {
      this.$emit('update', this.selectedLanguages);
      this.$emit('close-modal');
    },
  },
  destroyed() {
    document.body.classList.remove('noscroll');
  },
};

</script>

<style lang="scss">
@import 'general';

.translation-add {
  .modal-header {
    text-align: center;
  }
  .translation-modal-desc {
    @include p_large;
    text-align: center;
    margin-bottom: 32px;
  }
  .p-select-wrap {
    margin-bottom: 32px;
  }
  .multiselect__content {
    width: 100%;
    display: flex !important;
    overflow: visible;
    position: relative;
    flex-wrap: wrap;
  }
  .multiselect__element {
    min-width: 50%;
  }
  .multiselect__content-wrapper {
    position: relative;
    background: #fff;
    width: 100%;
    height: auto;
    max-height: none !important;
    overflow: visible;
    z-index: 50;
  }
  .option__desc {
    display: flex;
    align-items: center;
  }
}
</style>

