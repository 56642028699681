<template>
<transition name="fade">
  <div class="milestone-modal-wrap dashboard-modal">
    <div class="container">
      <ModalHeader quitText="Close" @close-modal="checkConfirm" />
      <div class="milestone-modal-content">
        <div class="milestone-modal-title">
          <h2 v-if="selectedMilestone">
            {{ $t('dashboard.timeline.milestones.titles.edit_milestone') }}
          </h2>
          <h2 v-else>
            {{ $t('dashboard.timeline.milestones.titles.new_milestone') }}
          </h2>
        </div>
        <div class="milestone-modal-body">
          <TitledInput
            v-model="dataValue.name"
            class="milestone-title"
            :title="$t('dashboard.timeline.milestones.labels.title')"
            :suffix="dataValue.name ? `${dataValue.name.length}/80` : '80'"
            :maxLength="80"
            :errorMessage="errors.name"
            :errorSubMessage="errors.nameSub"
            @input="handleFormInput('name', $event)"
          />
          <div class="milestone-date">
            <v-datepicker
              :value="endDate"
              class="modal-date"
              :placeholder="$t('dashboard.timeline.milestones.labels.end_date')"
              format="M/d/yyyy"
              :disabled-dates="disabledDates"
              @input="handleDateSelect"
              @focusin.native="dateActive = true"
              @focusout.native="dateActive = false"
            />
            <div
              v-if="dateActive || dataValue.date"
              class="date-label"
            >
              {{ $t('dashboard.timeline.milestones.labels.end_date') }}
            </div>
            <ErrorMessage
              v-if="errors.date"
              :errorMessage="errors.date"
            />
          </div>

          <TitledInput
            v-model="dataValue.description"
            class="milestone-description"
            :title="$t('dashboard.rewards.modal.labels.description')"
            :suffix="dataValue.description ? `${dataValue.description.length}/1000` : '1000'"
            :maxLength="1000"
            :rows="5"
          />
          <div class="hr" />
          <div class="milestone-fees">
            <div class="fees-info">
              <div class="fees-item">
                <h3>{{ $t('dashboard.timeline.milestones.titles.fees') }}</h3>
                <div v-if="milestones.length === 0">
                  {{ $t('dashboard.timeline.milestones.instructions_first') }}
                </div>
                <div v-else>
                  {{ $t('dashboard.timeline.milestones.instructions') }}
                </div>
              </div>
              <div class="fees-item">
                <div class="fees-item-row">
                  <div class="funds-label">
                    {{ $t('dashboard.timeline.milestones.labels.starting_funds') }}
                  </div>
                  <div class="funds-percent">
                    {{ `${initialReleasePercent}%` }}
                  </div>
                  <div class="funds-amount">
                    {{ initialReleaseCents | from_cents }}
                  </div>
                </div>
                <div class="fees-item-row">
                  <div class="funds-label">
                    {{ $t('dashboard.timeline.milestones.labels.milestone_funds') }}
                  </div>
                  <div class="funds-percent">
                    {{ `${totalMilestonePercent}%` }}
                  </div>
                  <div class="funds-amount">
                    {{ totalMilestoneCents | from_cents }}
                  </div>
                </div>
                <div class="total-fee">
                  <div>
                    {{ $t('dashboard.timeline.milestones.labels.total_fee') }}
                  </div>
                  <div>
                    {{ $tc('dashboard.timeline.milestones.labels.project_fee', projectFee) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="fees-inputs">
              <TitledInput
                class="fees-input"
                :value="`${dataValue.release_percent}%`"
                :suffix="$t(
                  'dashboard.timeline.milestones.total',
                  { total: $options.filters.from_cents(fundingGoal) }
                )"
                :isDisabled="true"
              />
              <TitledInput
                class="fees-input"
                :value="releaseAmount"
                :maxLength="80"
                :isDisabled="true"
              />
            </div>
            <Slider
              :slideValue="slideValue"
              :min="0"
              :max="100"
              :lowerLimit="lowerLimit"
              :upperLimit="upperLimit"
              @input="handleSlideInput"
              @mouseLeave="handleSlideInput"
            />
          </div>
          <ErrorMessage
            v-if="milestoneError"
            :errorMessage="milestoneError"
          />
          <div>
            <button
              v-if="selectedMilestone"
              class="btn-milestone"
              @click="onUpdateMilestone"
            >
              {{ $t('dashboard.timeline.milestones.buttons.update_milestone') }}
            </button>
            <button
              v-else
              class="btn-milestone"
              @click="onAddMilestone"
            >
              {{ $t('dashboard.timeline.milestones.buttons.add_milestone') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <PromptModal
      v-if="confirmModalOpen"
      :title="$t('modal.confirm_cancel')"
      :description="$t('modal.exit')"
      :submitText="$t('modal.exit_anyway')"
      @close-modal="confirmModalOpen = false"
      @submit="cancelMilestone"
    />
  </div>
</transition>
</template>

<script>
import moment from 'moment';

import ErrorMessage from '@/components/widget/ErrorMessage';
import ModalHeader from '@/components/widget/ModalHeader';
import PromptModal from '@/components/widget/PromptModal';
import TitledInput from '@/components/widget/TitledInput';
import Slider from '@/components/widget/Slider';
import FormMixin from '@/mixins/Form';

import { listingFee, reservePercent } from '@/utils/objectUtils';
import { validateLength } from '@/utils/stringUtils';
import {
  createMilestone,
  updateMilestone,
} from '@/utils/api';

const milestoneMinPercent = 5;

const emptyMilestone = () => ({
  name: '',
  description: '',
  release_percent: milestoneMinPercent,
  date: null,
});

export default {
  name: 'milestone-modal',
  components: {
    ErrorMessage,
    ModalHeader,
    PromptModal,
    TitledInput,
    Slider,
  },
  mixins: [FormMixin],
  props: {
    selectedMilestone: {
      type: Object,
      default: () => null,
    },
    fundsRaised: {
      type: Number,
      default: 0,
    },
    fundingGoal: {
      type: Number,
      default: 0,
    },
    milestones: {
      type: Array,
      default: () => [],
    },
    formName: {
      type: String,
      default: 'project_milestones',
    },
  },
  data() {
    return {
      dataValue: emptyMilestone(),
      rules: {
        name: {
          required: true,
          validator: val => validateLength(val, { minLength: 3 }),
          subError: { key: 'errors.suberror_char_range', data: { min: 3, max: 40 } },
        },
        date: {
          required: true,
        },
      },
      dateActive: false,
      disabledDates: {
        to: moment().toDate(),
      },
      milestonesSum: 0,
      confirmModalOpen: false,
      milestoneError: null,
    };
  },
  computed: {
    slideValue() {
      return this.dataValue.release_percent;
    },
    lowerLimit() {
      return milestoneMinPercent;
    },
    upperLimit() {
      return 100 - (this.milestonesSum + this.projectFee);
    },
    endDate() {
      if(this.dataValue.date) {
        return moment(this.dataValue.date).toDate();
      }
      return null;
    },
    initialReleaseCents() {
      return (this.initialReleasePercent / 100) * this.fundingGoal;
    },
    initialReleasePercent() {
      return 100 - (this.milestonesSum + this.projectFee + this.dataValue.release_percent);
    },
    totalMilestonePercent() {
      return this.milestonesSum + this.dataValue.release_percent;
    },
    totalMilestoneCents() {
      return (this.totalMilestonePercent / 100) * this.fundingGoal;
    },
    releaseAmount() {
      const amount = (this.dataValue.release_percent / 100) * this.fundingGoal;
      return this.$options.filters.from_cents(amount);
    },
    projectFee() {
      return listingFee(this.totalMilestonePercent);
    },
  },
  methods: {
    async onAddMilestone() {
      if(!this.validateAll()) {
        this.milestoneError = this.$t('dashboard.rewards.modal.error');
        return;
      }
      const milestoneData = {
        name: { en: this.dataValue.name },
        description: { en: this.dataValue.description },
        release_percent: this.dataValue.release_percent,
        date: this.dataValue.date.unix(),
      };
      try {
        const projectId = this.$route.params.id;
        await createMilestone({
          projectId,
          milestone: milestoneData,
        });
        this.$emit('refresh-project-data');
        this.$emit('close-modal');
      } catch(err) {
        console.log('Error in add milestone: ', err);
      }
    },
    async onUpdateMilestone() {
      try {
        const projectId = this.$route.params.id;
        await updateMilestone({
          projectId,
          milestoneId: this.selectedMilestone.id,
          milestone: {
            name: { en: this.dataValue.name },
            description: { en: this.dataValue.description },
            date: this.dataValue.date.unix(),
            release_percent: this.dataValue.release_percent,
          },
        });
        this.$emit('refresh-project-data');
        this.$emit('close-modal');
      } catch(err) {
        console.log('error in update milestone: ', err);
      }
    },
    cancelMilestone() {
      this.confirmModalOpen = false;
      this.$emit('close-modal');
    },
    handleSlideInput(val) {
      this.dataValue.release_percent = parseInt(val);
    },
    handleDateSelect(val) {
      this.handleFormInput('date', val);
      this.dataValue.date = moment(val);
    },
    checkConfirm() {
      if(!this.isModified()) {
        this.$emit('close-modal');
      } else {
        this.confirmModalOpen = true;
      }
    },
    isModified() {
      const original = this.selectedMilestone || emptyMilestone();
      const newData = this.dataValue;
      return original.name !== newData.name
      || original.description !== newData.description
      || original.release_percent !== newData.release_percent
      || !(newData.date === original.date || newData.date.isSame(original.date));
    },
  },
  created() {
    const { selectedMilestone } = this;
    if(selectedMilestone) {
      this.dataValue = {
        ...this.selectedMilestone,
        date: this.selectedMilestone.date.clone(),
      };
      const originalReleasePercent = selectedMilestone.release_percent || 0;
      this.milestonesSum = reservePercent(this.milestones) - originalReleasePercent;
    } else {
      this.milestonesSum = reservePercent(this.milestones);
    }
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.milestone-modal-wrap {
  .milestone-modal-content {
    max-width: 645px;
    margin: auto;
  }
  .milestone-modal-title {
    text-align: center;
    margin: 24px 0 32px;
  }
  .hr {
    margin: 40px 0 32px;
  }
  .btn-milestone {
    @include button($main-black, large);
    width: 100%;
    margin-top: 40px;
    margin-bottom: 72px;
  }
  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.milestone-date {
  position: relative;
  width: 298px;

  .modal-date {
    width: 100%;
    margin: 8px 0 0;
    input {
      @include input;
      margin-top: 0;
      height: 45px;
      cursor: pointer;
      background: url('~@/static/img/icons/ic_cal.svg');
      background-repeat: no-repeat;
      background-position: 95% 40%;
      background-size: 24px 24px;
      &::placeholder {
        color: $black-light;
        opacity: 1;
      }
    }
  }

  .date-label {
    @include subtitle;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 0;
    position: absolute;
    background-color: #fff;
    margin-left: 12px;
    top: -12px;
    font-size: 12px;
    padding: 0 4px;
    pointer-events: none;
    transition: $speed2 ease all;
  }
}

.milestone-fees {
  .fees-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .fees-item {
      width: 50%;
      &:first-child {
        padding-right: 16px;
      }
      .fees-item-row {
        @include h5;
        color: $grey;
        padding: 6px 0 0 16px;
        display: flex;
        height: 60px;
        align-items: center;
        border-bottom: 1px solid $border-light;
        .funds-percent {
          text-align: right;
          margin: 0 8px 0 auto;
        }
        .funds-amount {
          text-align: right;
          color: $main-black;
          min-width: 80px;
        }
      }
    }
    .total-fee {
      @include h4;
      color: $main-black;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 16px;
      margin-top: 30px;
    }
  }
  .fees-inputs {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    .fees-input {
      width: 50%;
      &:first-child input {
        border-right: none;
      }
    }
  }
}

@media (max-width: $tablet-width-large) {
  .milestone-fees .fees-info {
    .fees-item {
      width: 100%;
      margin: 16px auto 0;
      .fees-item-row {
        padding-left: 0;
      }
    }
    .total-fee {
      padding-left: 0;
    }
  }
}
@media (max-width: 640px) {
  .milestone-date {
    width: 100%;
  }
  .milestone-fees .fees-inputs {
    flex-wrap: wrap;
    .fees-input {
      width: 100%;
      margin: 0 auto;
      &:first-child input {
        border-right: 1px solid $border-light;
        border-bottom: none;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .milestone-fees .fees-info .fees-item:first-child {
    padding-right: 0;
  }
}

</style>
