<template>
<div v-if="project" class="dashboard-accounts-wrap">
  <DashboardHeader
    :tab="$t('dashboard.account.title')"
    :previewCode="project.preview_code"
    :crowdfunding="project.crowdfunding"
  />
  <div class="container">
    <div class="dashboard-content">
      <h1>{{ $t('dashboard.account.title') }}</h1>
      <h3>{{ $t('dashboard.account.identity') }}</h3>
      <Section :info="$t('dashboard.account.email')">
        <h5>{{ project.contact_email }}</h5>
      </Section>
      <div class="bank-info-title">
        <h3>{{ $t('dashboard.account.bank') }}</h3>
        <div :class="`bank-info-status ${status}`">
          {{ statusText }}
        </div>
      </div>
      <Section :info="$t('dashboard.account.bank_details')" class="bank-info">
        <TitledInput
          v-model="dataValue.routing_number"
          name="bank-routing"
          class="bank-routing"
          :errorMessage="errors.routing_number"
          :title="$t('form.bank.labels.routing_number')"
          @input="routingValid = handleFormInput('routing_number', $event)"
        />
        <TitledInput
          v-model="dataValue.account_number"
          name="bank-account"
          :errorMessage="errors.account_number"
          :title="$t('form.bank.labels.account_number')"
          @input="accountValid = handleFormInput('account_number', $event)"
        />
      </Section>
      <Section :info="$t('dashboard.account.bank_confirmation')">
        <ImageUpload
          id="upload-bank"
          class="upload-bank"
          :loading="imageLoading"
          @file-select="validateImage($event)"
        />
        <ErrorList :errors="imageUploadErrors" />
      </Section>
      <Section class="bank-submit-wrap">
        <ErrorMessage
          v-if="submitError"
          :errorMessage="submitError"
        />
        <PButton
          :disabled="!canSubmit"
          :text="$t('dashboard.account.bank_submit')"
          :animate="submitAnimate"
          class="bank-submit"
          @click="checkSubmitAccount"
        />
      </Section>
    </div>
  </div>
  <PromptModal
    v-if="showBankWarning"
    :title="$t('confirm')"
    :description="$t('dashboard.account.bank_warning')"
    :submitText="$t('submit')"
    @close-modal="showBankWarning = false"
    @submit="submitAccount"
  />
  <PromptModal
    v-if="showSubmittedModal"
    :title="$t('dashboard.account.submitted_title')"
    :description="$t('dashboard.account.submitted_text')"
    :submitText="$t('confirm')"
    @close-modal="showSubmittedModal = false"
    @submit="showSubmittedModal = false"
  />
</div>
</template>

<script>
import * as api from '@/utils/api';
import formMixin from '@/mixins/Form';
import DashboardHeader from '@/components/builder/DashboardHeader';
import ErrorList from '@/components/widget/ErrorList.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import Section from '@/components/widget/Section';
import ImageUpload from '@/components/widget/ImageUpload';
import PButton from '@/components/widget/PButton';
import PromptModal from '@/components/widget/PromptModal.vue';
import TitledInput from '@/components/widget/TitledInput';
import { updateProjectBankInfo } from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';
import { AccountInformationStatus } from '@/utils/constants';
import { validateLength } from '@/utils/stringUtils';
import { validateImage as validateImageUtil } from '@/utils/objectUtils';

export default {
  name: 'dashboard-accounts',
  components: {
    DashboardHeader,
    ErrorList,
    ErrorMessage,
    Section,
    ImageUpload,
    PButton,
    PromptModal,
    TitledInput,
  },
  mixins: [formMixin],
  data() {
    return {
      dataFormName: 'bank',
      project: null,
      dataValue: {
        routing_number: '',
        account_number: '',
        proof: null,
      },
      accountValid: false,
      routingValid: false,
      submitError: null,
      submitAnimate: false,
      imageUploadErrors: null,
      imageLoading: false,
      showBankWarning: false,
      showSubmittedModal: false,
      rules: {
        routing_number: {
          required: true,
          validator: val => val.length === 9,
        },
        account_number: {
          required: true,
          validator: val => validateLength(val, { minLength: 4, maxLength: 17 }),
        },
      },
    };
  },
  computed: {
    status() {
      return this.project.account_information_status;
    },
    statusText() {
      const text = AccountInformationStatus[this.status];
      return (text === AccountInformationStatus.NONE) ? '' : text;
    },
    canSubmit() {
      return this.routingValid && this.accountValid && this.dataValue.proof;
    },
  },
  methods: {
    checkSubmitAccount() {
      this.submitError = null;
      if(!this.validateAll()) {
        return;
      }
      const { PENDING, ACCEPTED } = AccountInformationStatus;
      if(this.statusText === PENDING || this.statusText === ACCEPTED) {
        this.showBankWarning = true;
      } else {
        this.submitAccount();
      }
    },
    async submitAccount() {
      this.showBankWarning = false;
      this.submitAnimate = true;
      try {
        await updateProjectBankInfo({ projectId: this.project.id, data: this.dataValue });
        this.project.account_information_status = 'PENDING';
        this.showSubmittedModal = true;
      } catch(e) {
        this.submitError = this.$t(firstErrorKey(e.errors));
        this.checkApiErrors(e.errors);
      }
      this.submitAnimate = false;
    },
    validateImage(file) {
      const requirements = { ext: ['jpg', 'jpeg', 'png', 'pdf'], size: 5000000 };
      validateImageUtil(requirements, file, (validFile, errors) => {
        if(errors) {
          this.imageUploadErrors = errors;
        } else {
          this.dataValue.proof = validFile;
        }
      });
    },
  },
  async created() {
    this.project = await api.getCreatorProjectDetail(this.$route.params.id);
  },
};

</script>

<style lang="scss">
@import 'widgets';

.dashboard-content {
  margin-bottom: 96px;
  .bank-info-title {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 24px;
    .bank-info-status {
      @include h4;
      align-content: flex-end;
      &.PENDING { color: $main-blue; }
      &.ACCEPTED { color: $main-green; }
      &.REJECTED { color: $main-red; }
    }
  }
  .bank-info .section-right {
    max-width: 400px;
  }
  .upload-bank {
    height: 200px;
  }
  .bank-submit-wrap {
    padding-top: 24px;
    .error {
      padding: 0 0 8px 0;
    }
    .bank-submit {
      @include button($main-black, large);
      position: relative;
      float: right;
    }
  }
  .p-input-wrap.bank-routing {
    margin-bottom: 16px;
  }
}
</style>
