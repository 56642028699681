<template>
<div v-if="project" class="dashboard">
  <div class="dashboard-main-wrap">
    <div class="container-content">
      <div class="dashboard-info">
        <div class="dashboard-info-left">
          <h1>{{ project.name }}</h1>
          <div class="team">
            <router-link class="creator" :to="{ name: 'user-public', params: { id: project.creator.id } }">
              <img :src="project.creator | avatar">
              <span>{{ project.creator.name }}</span>
            </router-link>
            <div class="manage" @click="showManageTeamModal = true">
              {{ $t('dashboard.manage') }}
            </div>
          </div>
        </div>
        <div class="dashboard-info-right">
          <router-link class="preview" :to="{ name: 'project-preview', params: { code: project.preview_code }}">
            {{ $t('dashboard.preview') }}
          </router-link>
          <button
            v-if="project.crowdfunding"
            :disabled="!basicsStatus.canPublish"
            class="publish"
            :title="[!basicsStatus.canPublish ? 'Please complete the required fields to be able to publish' : '']"
            @click="showUnimplemented = true"
          >
            {{ $t('dashboard.publish.title') }}
          </button>
          <div v-else class="publish" @click="showSubmitStorefront = true">
            {{ $t('dashboard.submit') }}
          </div>
        </div>
      </div>
      <div class="dashboard-menu">
        <TabBar
          :tabs="$t('dashboard.tabs')"
          :activeTab="$t('dashboard.tabs')[0]"
        />
      </div>
      <div class="dashboard-main">
        <DashboardLink
          :copy="$t('dashboard.basics')"
          icon="basics_small.png"
          link="dashboard-basics"
        >
          {{ basicsStatus.completed }} {{ $t('of') }} {{ basicsStatus.total }}
        </DashboardLink>
        <DashboardLink
          :copy="$t('dashboard.overview')"
          icon="overview_small.png"
          link="dashboard-overview"
        >
          {{ $t('start') }}
        </DashboardLink>
        <DashboardLink
          :copy="rewardsCopy"
          icon="rewards_small.png"
          link="dashboard-rewards"
        >
          {{ rewardsText }}
        </DashboardLink>
        <DashboardLink
          v-if="project.funding_type === CROWDFUNDING"
          :copy="$t('dashboard.timeline')"
          icon="timeline_small.png"
          link="dashboard-timeline"
        >
          {{ project.milestones.length }} {{ $t('dashboard.milestones') }}
        </DashboardLink>
        <DashboardLink
          :copy="$t('dashboard.account')"
          icon="account_small.png"
          link="dashboard-account"
        >
          {{ $t('start') }}
        </DashboardLink>
        <!--
        <DashboardLink
          :copy="$t('dashboard.translation')"
          icon="translation.png"
          link="dashboard-translation"
        />
        -->
      </div>
      <hr>
      <div class="dashboard-secondary">
        <DashboardLink
          :copy="$t('dashboard.manager')"
          :to="{name: 'manager', params: { id: project.id }}"
          icon="manager.png"
        />
        <DashboardLink
          :copy="$t('dashboard.storefront')"
          icon="storefront.png"
          @click="showUnimplemented = true"
        />
      </div>
      <div class="dashboard-secondary dashboard-faq">
        <DashboardLink
          :copy="$t('dashboard.faq')"
          :secondary="true"
          link="dashboard-faq"
        >
          {{ project.faqs.length }}
        </DashboardLink>
        <!--
        <DashboardLink
          :copy="$t('dashboard.bonus')"
          :secondary="true"
          link="dashboard-bonus"
        >
          {{ bonuses.length }}
        </DashboardLink>
        -->
      </div>
      <Handbook />
      <Search />
    </div>
  </div>

  <SubmitStorefrontModal
    v-if="showSubmitStorefront"
    @submit="progressToSuccess"
    @close-modal="showSubmitStorefront = false"
  />
  <SubmitSuccessModal
    v-if="showSubmitSuccess"
    :project="project"
    @close-modal="showSubmitSuccess = false"
  />
  <UnimplementedModal
    v-if="showUnimplemented"
    @close-modal="showUnimplemented = false"
  />
  <ManageTeamModal
    v-if="showManageTeamModal"
    :project="project"
    @close-modal="showManageTeamModal = false"
  />
</div>
</template>

<script>
import * as api from '@/utils/api';
import DashboardLink from '@/components/builder/DashboardLink.vue';
import Handbook from '@/components/manager/Handbook.vue';
import Search from '@/components/manager/Search.vue';
import UnimplementedModal from '@/components/widget/UnimplementedModal.vue';
import SubmitStorefrontModal from '@/components/widget/SubmitStorefrontModal.vue';
import SubmitSuccessModal from '@/components/widget/SubmitSuccessModal.vue';
import ManageTeamModal from '@/components/widget/ManageTeamModal.vue';
import TabBar from '@/components/widget/TabBar.vue';
import { ProjectType } from '@/utils/constants';
import { PROJECT_SET } from '@/store/actions';

export default {
  name: 'dashboard',
  components: {
    DashboardLink,
    Handbook,
    Search,
    UnimplementedModal,
    SubmitStorefrontModal,
    SubmitSuccessModal,
    ManageTeamModal,
    TabBar,
  },
  data() {
    return {
      project: null,
      // TODO - get from API
      bonuses: [],
      showUnimplemented: false,
      showSubmitStorefront: false,
      showSubmitSuccess: false,
      showManageTeamModal: false,
      ...ProjectType,
    };
  },
  computed: {
    basicsStatus() {
      return this.$store.getters.basicsStatus;
    },
    rewardsCopy() {
      if(this.project.crowdfunding) {
        return this.$t('dashboard.rewards');
      }
      return this.$t('dashboard.products');
    },
    rewardsText() {
      const len = this.project.rewards.length;
      if(len === 0) {
        return this.$t('start');
      }
      if(this.project.crowdfunding) {
        return `${len} ${this.$tc('reward_count', len)}`;
      }
      return `${len} ${this.$tc('product_count', len)}`;
    },
  },
  methods: {
    progressToSuccess() {
      this.showSubmitStorefront = false;
      this.showSubmitSuccess = true;
    },
  },
  async created() {
    try {
      this.project = await api.getCreatorProjectDetail(this.$route.params.id);
      this.$store.commit(PROJECT_SET, this.project);
    } catch(err) {
      console.log('Error fetching project data: ', err);
    }
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.dashboard {
  .dashboard-menu {
    margin-top: 24px;
    border-bottom: 1px solid $border-light;
  }
  .campaign-manager {
    color: $main-blue;
    font-weight: 700;
  }
  .dashboard-main-wrap {
    background-image: linear-gradient($blue-light, $white);
  }
  .dashboard-info {
    display: flex;
    align-items: center;
    margin-top: 32px;
    .dashboard-info-left {
      width: 45%;

      > h1 {
        margin: 0 0 15px 0;
      }
      .team {
        display: flex;
        align-items: center;
        .creator {
          display: flex;
          align-items: center;
          margin-right: 24px;
          margin-bottom: 10px;

          > img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 9px;
          }
          > span {
            padding-top: 4px;
            @include subheading;
          }
        }
        .manage {
          @include button_small_text;
          top: -2px;
          color: $main-blue;
          cursor: pointer;
        }
      }
    }
    .dashboard-info-right {
      margin-left: auto;
      .preview {
        @include button($blue-med, small);
        color: $main-blue;
        padding: 3px 16px 0;
        margin-right: 24px;
      }
      .publish {
        @include button($main-black, small);
        color: #fbfbf3;
        padding: 3px 16px 0;
        width: 170px;
      }
    }
  }
  .dashboard-main {
    margin: 48px 0 56px;
    > a > div {
      margin-top: 16px;
    }
  }
  .dashboard-secondary {
    display: flex;
    justify-content: space-between;
    margin: 56px 0;
    > a {
      width: calc(50% - 24px);
    }
  }
}
</style>
