<template>
<div class="image-list-wrap">
  <div
    class="image-list"
    :class="{ dragging: dragging && !draggableDragging }"
  >
    <form
      id="image-list-form"
      class="img-upload-form"
      action=""
      enctype="multipart/form-data"
      @drop="dropUploadImage"
      @dragenter="dragStart"
      @dragleave="dragEnd"
      @dragend="dragEnd"
      @input="handleFileSelect"
    >
      <input
        class="img-upload"
        type="file"
        accept="image/*"
      >
    </form>
    <draggable
      v-model="dataImages"
      class="image-draggable"
      direction="horizontal"
      @start="startDraggable"
      @end="updateOrder"
    >
      <div
        v-for="(imgData, idx) in dataImages"
        :key="idx"
      >
        <div
          class="image-wrap"
          :style="{ 'background-image': `url(${imgData.url})` }"
          @dragenter="dragStart"
        >
          <Delete class="img-delete" @click="handleDelete(imgData, idx)" />
        </div>
      </div>
    </draggable>
  </div>
  <div v-if="loading || deleting" class="loading-overlay">
    <Spinner :size="64" />
  </div>
</div>
</template>

<script>
import draggable from 'vuedraggable';
import Delete from '@/components/svg/Delete.vue';
import Spinner from '@/components/widget/Spinner.vue';
import { deleteProjectImage } from '@/utils/api';
import DragAndDrop from '@/mixins/DragAndDrop';

export default {
  name: 'image-list',
  components: {
    draggable,
    Delete,
    Spinner,
  },
  mixins: [DragAndDrop],
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataImages: [...this.images],
      deleting: false,
      draggableDragging: false,
    };
  },
  watch: {
    images(newVal) {
      this.dataImages = [...newVal];
    },
  },
  methods: {
    async handleDelete(imgData, idx) {
      const { projectId } = this;
      const imageId = imgData.id;
      this.deleting = true;
      try {
        await deleteProjectImage({ projectId, imageId });
        const images = [...this.dataImages];
        images.splice(idx, 1);
        this.$emit('on-delete', { images, errors: null });
      } catch(e) {
        this.$emit('on-delete', { images: this.dataImages, errors: e.errors });
      }
      this.deleting = false;
    },
    startDraggable() {
      this.draggableDragging = true;
    },
    updateOrder(e) {
      this.draggableDragging = false;
      this.dragging = false;
      if(e.oldIndex !== e.newIndex) {
        this.$emit('on-order', this.dataImages);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.image-list-wrap {
  position: relative;
  width: 100%;

  .loading-overlay {
    position: absolute;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
  }
}
.image-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  border: 1px solid $border-light;
  padding: 8px 0 8px 24px;
  position: relative;
  .img-upload-form {
    position: absolute;
    width: 100%;
    height: 240px;
    left: 0;
    top: 0;
  }
  .image-draggable {
    display: flex;
    overflow-x: auto;
    padding: 16px 0 16px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 0;
      border: none;
    }
    &::-webkit-scrollbar-track {
      background-color: $grey-lightest;
      border-radius: 0;
      margin-right: 24px;
    }
  }
  &.dragging {
    border: 1px dashed $main-blue;
    .image-draggable {
      pointer-events: none;
    }
  }
  .image-draggable::-webkit-scrollbar:horizontal {
    height: 8px;
  }
  @media (max-width: 480px) {
    margin: 0 -10px;
  }
  .image-wrap {
    width: 382px;
    height: 216px;
    margin-right: 16px;
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-position: center;

    .img-delete {
      display: none;
      position: absolute;
      top: -8px;
      right: -8px;
      z-index: 100;
    }
    &:hover {
      transition: $speed3;
      .img-delete {
        display: block;
      }
    }
  }
}

</style>
