<template>
<div class="reward-list">
  <draggable
    v-model="rewards"
    class="dashboard-rewards-draggable"
    direction="horizontal"
    @end="updateOrder"
  >
    <div
      v-for="reward in rewards"
      :key="reward.id"
      class="reward-card-wrap"
    >
      <RewardCard
        :reward="reward"
        :projectId="project.id"
        :status="RewardStatus.ADD"
        :projectActive="projectActive"
        :rewardsKey="rewardsKey"
        :edit="edit"
        :currency="project.currency"
        @reward-add="$emit('reward-add')"
        @reward-edit="$emit('reward-edit', $event)"
      />
    </div>
  </draggable>
</div>
</template>

<script>
import draggable from 'vuedraggable';
import RewardCard from '@/components/widget/RewardCard.vue';
import { RewardStatus, ProjectStatus } from '@/utils/constants';
import { objectIdSort } from '@/utils';

export default {
  name: 'reward-list-edit',
  components: {
    draggable,
    RewardCard,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    rewardsInit: {
      type: Array,
      default: null,
    },
    order: {
      type: Array,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    projectStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      RewardStatus,
      rewards: [...(this.rewardsInit || [])],
    };
  },
  computed: {
    projectActive() {
      return this.projectStatus === ProjectStatus.ACTIVE;
    },
    rewardsKey() {
      return this.project.crowdfunding ? 'rewards' : 'products';
    },
  },
  watch: {
    rewardsInit(val) {
      this.rewards = objectIdSort([...val], this.order);
    },
  },
  methods: {
    updateOrder(e) {
      if(e.oldIndex !== e.newIndex) {
        const order = this.rewards.map(reward => reward.id);
        this.$emit('on-order', order);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.reward-list {
  margin: 16px 0 24px;
  .dashboard-rewards-draggable {
    display: flex;
    flex-wrap: wrap;
  }

  .reward-card-wrap {
    padding: 24px 48px 24px 0;
    display: flex;

    &:last-child {
      padding-right: 0;
    }
  }
}

@media (max-width: $mobile-width) {
  .reward-list .reward-card-wrap {
    width: 100%;
    padding: 8px 0;
  }
}
</style>
