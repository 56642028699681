<template>
<transition name="fade">
  <div class="bonus-modal-wrap dashboard-modal">
    <div class="container">
      <ModalHeader @hideModal="$emit('close-modal')" />
      <div class="bonus-modal-content">
        <div class="bonus-modal-title">
          <h2 v-if="dataValue.id">
            {{ $t('dashboard.bonus.edit') }}
          </h2>
          <h2 v-else>
            {{ $t('dashboard.bonus.new') }}
          </h2>
        </div>
        <div class="bonus-modal-body">
          <TitledInput
            v-model="dataValue.title"
            :title="$t('dashboard.bonus.bonus_title')"
            :suffix="dataValue.title ? `${dataValue.title.length}/40` : '40'"
            :maxLength="400"
            :errorMessage="errors.title"
            @input="handleFormInput('title', $event)"
          />
          <TitledInput
            v-model="dataValue.description"
            :title="$t('dashboard.bonus.bonus_description')"
            :suffix="dataValue.description ? `${dataValue.description.length}/400` : '400'"
            :maxLength="400"
            :rows="8"
            :errorMessage="errors.description"
            @input="handleFormInput('description', $event)"
          />
          <TitledInput
            v-model="dataValue.rules"
            :title="$t('dashboard.bonus.bonus_rules')"
            :suffix="dataValue.rules ? `${dataValue.rules.length}/400` : '400'"
            :maxLength="400"
            :rows="8"
            :errorMessage="errors.rules"
            @input="handleFormInput('rules', $event)"
          />
          <hr>
          <TitledInput
            v-model="dataValue.value"
            type="number"
            step="0.01"
            :title="$t('dashboard.bonus.bonus_value')"
            :suffix="'PLG'"
          />
          <TitledInput
            v-model="dataValue.number"
            type="number"
            step="1"
            :title="$t('dashboard.bonus.bonus_number')"
          />
          <PLGWallet
            :walletBalance="walletBalance.total"
            @input="walletBalance.value = $event"
          />
          <h4 class="bonus-open-text">
            {{ $t('dashboard.bonus.bonus_open') }}
          </h4>
          <form class="bonus-open">
            <Radio
              v-model="openRadio"
              radioValue="open"
              @input="setBonusOpen($event)"
            >
              {{ $t('dashboard.bonus.everyone') }}
            </Radio>
            <Radio
              v-model="openRadio"
              radioValue="backers"
              @input="setBonusOpen($event)"
            >
              {{ $t('dashboard.bonus.backers') }}
            </Radio>
          </form>
          <hr>
          <div class="bonus-total">
            <div class="bonus-total-text">
              {{ $t('total') }}
            </div>
            <div class="bonus-total-value">
              {{ $tc('project.trust.vote_modal.plg', bonusTotal.toLocaleString()) }}
            </div>
          </div>
          <PButton
            class="btn-bonus"
            :animate="saving"
            :disabled="!isValid"
            @click="addOrUpdateBonus"
          >
            {{ $t('dashboard.bonus.save') }}
          </PButton>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import * as api from '@/utils/api';
import ModalHeader from '@/components/widget/ModalHeader';
import TitledInput from '@/components/widget/TitledInput';
import Radio from '@/components/widget/Radio';
import PButton from '@/components/widget/PButton';
import FormMixin from '@/mixins/Form';
import PLGWallet from '@/components/pledge/PLGWallet';
import { validateLength } from '@/utils/stringUtils';

const emptyBonus = () => ({
  title: '',
  desc: '',
  rules: '',
  value: 1.00,
  number: 0,
});

export default {
  name: 'bonus-modal',
  components: {
    ModalHeader,
    TitledInput,
    PButton,
    PLGWallet,
    Radio,
  },
  mixins: [FormMixin],
  props: {
    formName: {
      type: String,
      default: 'project_bonus',
    },
    selectedBonus: {
      type: Object,
      default: () => null,
    },
    bonusItem: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataValue: emptyBonus(),
      openRadio: 'backers',
      walletBalance: { value: 2.45, total: 500, totalchecked: true },
      rules: {
        title: {
          required: true,
          validator: val => validateLength(val, { minLength: 3 }),
        },
        description: {
          required: true,
          validator: val => validateLength(val, { minLength: 3 }),
        },
        rules: {
          required: false,
        },
      },
      saving: false,
    };
  },
  computed: {
    bonusTotal() {
      return (this.dataValue.value || 0) * (this.dataValue.number || 0);
    },
  },
  methods: {
    selectedPayment() {
      this.$log.warn('selectedPayment');
    },
    setBonusOpen(type) {
      this.dataValue.open = type === 'open';
    },
    checkPaymentType() {
      this.$log.warn('checkPaymentType');
    },
    async addOrUpdateBonus() {
      this.saving = true;
      const { id, ...bonus } = this.dataValue;
      try {
        if(id) {
          await api.updateBonus(this.projectId, id, bonus);
        } else {
          await api.addBonus(this.projectId, bonus);
        }
      } catch(e) {
        console.log(e);
      }
      this.$emit('saved');
      this.saving = false;
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.bonus-modal-wrap {
  hr {
    margin: 40px 0 44px;
  }
  .bonus-modal-content {
    max-width: 645px;
    margin: auto;
    .p-checkbox .checkbox-text {
      color: $grey2;
    }
  }
  .bonus-modal-title {
    text-align: center;
    margin: 24px 0 40px;
  }
  .bonus-info {
    @include p_info;
    color: $black-med;
    margin-top: 8px;
  }
  .hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .bonus-open-text {
    margin-top: 24px;
  }
  .bonus-open {
    margin-top: 12px;
    .p-radio {
      margin-top: 8px;
      @include h5;
      color: $grey;
      &.checked {
        color: $main-black;
      }
      .p-radio-content {
        margin-top: 4px;
      }
    }
  }
  .bonus-total {
    display: flex;
    justify-content: space-between;
    .bonus-total-text {
      @include h4;
      color: $grey2;
    }
    .bonus-total-value {
      @include h3;
    }
  }
  .p-button {
    @include button($main-black, large);
    width: 100%;
    margin-top: 32px;
    margin-bottom: 72px;
  }
  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
