<template>
<transition name="fade">
  <div class="faq-modal-wrap dashboard-modal">
    <div class="container">
      <ModalHeader @close-modal="$emit('close-modal')" />
      <div class="faq-modal-content">
        <div class="faq-modal-title">
          <h2 v-if="item.id">
            {{ $t('dashboard.faq.update') }}
          </h2>
          <h2 v-else>
            {{ $t('dashboard.faq.new') }}
          </h2>
        </div>
        <div class="faq-modal-body">
          <TitledInput
            v-model="item.question"
            :title="$t('dashboard.faq.question')"
            :suffix="item.question ? `${item.question.length}/400` : '400'"
            :maxLength="400"
          />
          <TitledInput
            v-model="item.answer"
            :title="$t('dashboard.faq.answer')"
            :suffix="item.answer ? `${item.answer.length}/1000` : '1000'"
            :maxLength="1000"
            :rows="8"
          />
          <p class="faq-info">
            {{ $t('dashboard.faq.answer_hyperlink') }}
          </p>
          <PButton
            class="btn-faq"
            :animate="saving"
            :disabled="!isValid"
            @click="addOrUpdateQuestion"
          >
            {{ $t('dashboard.faq.save') }}
          </PButton>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import * as api from '@/utils/api';
import ModalHeader from '@/components/widget/ModalHeader.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import PButton from '@/components/widget/PButton';

export default {
  name: 'faq-modal',
  components: {
    ModalHeader,
    TitledInput,
    PButton,
  },
  props: {
    faqItem: {
      type: Object,
      default: () => {},
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      item: { ...this.faqItem },
      saving: false,
    };
  },
  computed: {
    isValid() {
      return this.item.question && this.item.answer;
    },
  },
  watch: {
    faqItem(val) {
      this.item = { ...val };
    },
  },
  methods: {
    async addOrUpdateQuestion() {
      this.saving = true;
      const { id, question, answer } = this.item;
      try {
        if(id) {
          await api.updateFAQItem({
            projectId: this.projectId,
            faqItemId: id,
            question: { en: question },
            answer: { en: answer },
          });
        } else {
          await api.addFAQItem({
            projectId: this.projectId,
            question: { en: question },
            answer: { en: answer },
          });
        }
      } catch(e) {
        console.log(e);
      }
      this.$emit('saved');
      this.saving = false;
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.faq-modal-wrap {
  .faq-modal-content {
    max-width: 645px;
    margin: auto;
  }
  .faq-modal-title {
    text-align: center;
    margin: 24px 0 40px;
  }
  .faq-info {
    @include p_info;
    color: $black-med;
    margin-top: 8px;
  }
  .hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .btn-faq {
    @include button($main-black, large);
    width: 100%;
    margin-top: 32px;
    margin-bottom: 72px;
  }
  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
