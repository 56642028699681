<template>
<div :class="{ 'img-upload-wrap': true, dragging }">
  <form
    :id="id"
    class="img-upload-form"
    action=""
    enctype="multipart/form-data"
    @drop="dropUploadImage"
    @dragenter="dragStart"
    @dragleave="dragEnd"
    @dragend="dragEnd"
    @input="handleFileSelect"
  >
    <label class="img-upload-area" :for="`image-upload-input${id}`">
      <slot>
        <div
          v-if="backgroundImage"
          class="img-upload-background"
          :style="{'background-image': `url(${backgroundImage})`}"
        />
        <div v-if="!loading" class="img-upload-button">
          <Upload color="#2E40EA" />
          <h4>{{ $t('widgets.image_upload.drag_drop') }}</h4>
          <div>{{ $t('widgets.image_upload.browse_file') }}</div>
        </div>
      </slot>
      <div v-if="loading" class="img-upload-spinner">
        <Spinner :size="64" />
      </div>
    </label>
    <input
      :id="`img-upload-input${id}`"
      class="img-upload"
      type="file"
      accept="image/*"
      :disabled="isDisabled"
    >
  </form>
</div>
</template>

<script>
import Upload from '@/components/svg/Upload.vue';
import Spinner from '@/components/widget/Spinner';
import DragAndDrop from '@/mixins/DragAndDrop';

export default {
  name: 'img-upload',
  components: {
    Upload,
    Spinner,
  },
  mixins: [DragAndDrop],
  props: {
    id: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    backgroundImage: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.img-upload-wrap {
  border: 1px dashed $border-light;
  &.dragging {
    border-color: $main-blue;
  }
}
.img-upload-form {
  position: relative;
  height: 100%;
  width: 100%;
}
.img-upload-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.img-upload {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
.img-upload + label * {
  pointer-events: none;
}
.img-upload-button, .img-upload-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
  z-index: 10;
  background: rgba($white, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;

  h4 {
    margin-top: 16px;
  }

  div {
    color: $main-blue;
    @include button_small_text;
    margin-top: 8px;
  }
}
</style>
