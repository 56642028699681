<template>
<Modal
  :submitable="false"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <p>{{ $t('dashboard.publish.modal.titles.submit') }}</p>
    <div class="modal-container">
      <div class="modal-bg">
        <div class="content-paragraph">
          {{ $t('dashboard.publish.modal.labels.submit') }}
        </div>
      </div>

      <router-link class="button-primary" :to="{ name: 'project-preview', params: { code: project.preview_code }}">
        {{ $t('dashboard.publish.modal.buttons.go_preview') }}
      </router-link>
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';

export default {
  components: { Modal },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.modal-inner p {
  text-align: center;
  @include p_large;
}
.modal-bg .content-paragraph {
  text-align: center;
  padding: 48px 0;
  font-size: 24px;
  @include h2;
}
.modal-inner .submit-button {
  margin-top: 40px;
}
.modal-header {
  font-size: 24px;
  text-align: center;
  margin: 0;
}
.modal-bg {
  background-image: url('~@/static/img/art/review-stars.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
.modal-container {
  margin: 0 auto;
  width: 78%;
}
.button-primary {
  @include blue-button('full');
  margin-top: 20px;
}
.button-primary:hover {
  color: $white;
}

</style>
