<template>
<transition name="drawer-slide" appear>
  <div
    v-if="info"
    class="drawer-outer"
  >
    <div v-on-clickaway="closeDrawer" class="drawer-inner">
      <div class="drawer-header">
        <div class="drawer-title">
          {{ info.title }}
        </div>
        <div class="drawer-close">
          <img
            class="hide"
            :src="require('@/static/img/icons/ic_close.png')"
            @click="closeDrawer"
          >
        </div>
      </div>
      <div class="drawer-content">
        <slot name="intro">
          <p>{{ info.intro }}</p>
        </slot>
        <slot name="sections">
          <ol>
            <li
              v-for="(section, index) in info.sections"
              :key="index"
            >
              <h4>
                {{ section.title }}
              </h4>
              <p>
                {{ section.content }}
              </p>
            </li>
          </ol>
        </slot>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'drawer',
  mixins: [clickaway],
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleEscape(e) {
      if(e.key === 'Escape') {
        this.$emit('hide');
      }
    },
    closeDrawer() {
      this.$emit('hide');
      document.removeEventListener('keydown', this.handleEscape);
      document.body.classList.remove('noscroll');
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscape);
    document.body.classList.add('noscroll');
  },

};
</script>

<style lang="scss">
@import 'widgets';

.drawer-outer {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 1999;
}
.drawer-slide-enter-active, .drawer-slide-leave-active {
  transition: all $speed4 ease;
}
.drawer-slide-enter-to {
  transform: translateX(0);
}
.drawer-slide-leave-to, .drawer-slide-enter {
  transform: translateX(480px);
}
.drawer-inner {
  width: 480px;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 2000;
  background-color: $white;
  border-left: 1px solid $border-light;
  overflow: scroll;

  @media (max-width: $tablet-width) {
    margin: 0 auto;
    width: 100%;
  }
}
.drawer-close {
  .hide {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

.drawer-header {
  @include h4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 36px;
  background: $bg-grey-light;
  border-bottom: 1px solid $border-light;
}
.drawer-content {
  padding: 24px 32px 32px;
  ol {
    padding-inline-start: 16px;
    li {
      list-style: disc;
      margin-top: 24px;
    }
  }
}

</style>
