<template>
<Modal
  class="translation-explainer"
  :title="$t(slide.title)"
  :submitText="lastPage ? $t('done') : $t('next')"
  @submit="nextSlide"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <Carousel
      ref="carousel"
      :slideCount="slides.length"
      :slideWidth="560"
      :gutter="0"
      @slide-change="page = $event"
    >
      <div
        v-for="(s, index) in slides"
        :key="`slide${index}`"
        class="translation-explainer-body"
      >
        <div class="translation-explainer-desc">
          {{ $t(s.desc) }}
        </div>
        <div class="translation-explainer-info">
          <div class="translation-explainer-background" />
          <div class="translation-explainer-image-wrap" :style="s.wrapStyle">
            <img
              class="translation-explainer-image"
              :style="s.imgStyle"
              :src="require(`@/static/img/${s.img}`)"
            >
          </div>
        </div>
      </div>
    </Carousel>
    <div class="translation-explainer-nav">
      <div
        v-for="(_, index) in slides"
        :key="index"
        :class="{ active: index === page }"
        @click="$refs.carousel.goToSlide(index)"
      />
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal';
import Carousel from '@/components/widget/Carousel';

export default {
  name: 'translation-explainer-modal',
  components: {
    Modal,
    Carousel,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      page: 0,
      slides: [
        {
          title: 'dashboard.translation.explainer.title1',
          desc: 'dashboard.translation.explainer.desc1',
          img: 'translation_explainer1.png',
        },
        {
          title: 'dashboard.translation.explainer.title2',
          desc: 'dashboard.translation.explainer.desc2',
          img: 'translation_explainer2.png',
          wrapStyle: { top: '90px' },
        },
        {
          title: 'dashboard.translation.explainer.title3',
          desc: 'dashboard.translation.explainer.desc3',
          img: 'translation_explainer3.png',
          imgStyle: { width: '410px' },
        },
      ],
    };
  },
  computed: {
    slide() {
      return this.slides[this.page];
    },
    lastPage() {
      return this.page === this.slides.length - 1;
    },
  },
  methods: {
    nextSlide() {
      if(this.lastPage) {
        this.$emit('close-modal');
      } else {
        this.$refs.carousel.nextSlide();
      }
    },
  },
  mounted() {
    this.selectedLanguages = this.selected;
  },
  destroyed() {
    document.body.classList.remove('noscroll');
  },
};

</script>

<style lang="scss">
@import 'general';

.translation-explainer {
  .modal-header {
    text-align: center;
  }
  .translation-explainer-desc {
    @include p_large;
    text-align: center;
    margin-bottom: 16px;
  }
  .translation-explainer-body {
    width: 560px;
  }
  .translation-explainer-info {
    margin-top: 32px;
    min-height: 370px;
  }
  .translation-explainer-background {
    background-color: $grey-light;
    width: 340px;
    height: 340px;
    border-radius: 170px;
    z-index: 1;
    margin: 0 auto;
  }
  .translation-explainer-image-wrap {
    position: absolute;
    width: 560px;
    top: 176px;
    z-index: 2;
    display: flex;
    justify-content: center;
    .translation-explainer-image {
      width: 320px;
      height: auto;
    }
  }
  .pc-carousel {
    overflow: hidden;
    .pc-carousel-nav {
      top: 200px;
      width: 96%;
      left: 2%;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
  .translation-explainer-nav {
    display: flex;
    height: 4px;
    margin: 16px 0 32px;
    justify-content: center;
    > div {
      background-color: #eaebfc;
      width: 40px;
      cursor: pointer;
      &.active {
        background-color: $main-blue;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>

