<template>
<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  @click="$emit('click')"
>
  <g stroke="none" fill="none" fill-rule="evenodd">
    <path d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 Z" fill="#EC4A29" />
    <path d="M18,13 L6,13 C5.448,13 5,12.553 5,12 C5,11.447 5.448,11 6,11 L18,11 C18.552,11 19,11.447 19,12 C19,12.553 18.552,13 18,13 Z" fill="#FFFFFF" fill-rule="nonzero" />
  </g>
</svg>
</template>

<script>

export default {
  name: 'delete',
};
</script>
