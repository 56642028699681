<template>
<form
  :id="id"
  action=""
  enctype="multipart/form-data"
  @input="handleSelect"
>
  <label class="file-upload-button" :for="`file-upload-input${id}`">
    <div v-if="loading" class="file-upload-spinner">
      <Spinner :size="32" />
    </div>
    <span v-else class="file-upload-text">
      {{ text }}
    </span>
  </label>
  <input
    :id="`file-upload-input${id}`"
    class="file-upload"
    type="file"
    :accept="accept"
    :disabled="loading"
  >
</form>
</template>

<script>
import Spinner from '@/components/widget/Spinner';

export default {
  name: 'file-upload-button',
  components: {
    Spinner,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  methods: {
    handleSelect(e) {
      const file = e.target.files[0];
      this.$emit('file-select', file);
      e.target.value = '';
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.file-upload-text {
  @include link;
  color: $main-blue;
}

.file-upload {
  width: 0;
  max-width: 0;
}

</style>
