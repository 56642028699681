<template>
<div class="p-date-wrap">
  <v-datepicker
    :value="value"
    class="p-date"
    :class="{ 'no-suffix': !suffix }"
    :clear-button="showClear"
    :format="format"
    :disabled-dates="disabledDates"
    @changedMonth="$emit('changed-month', $event)"
    @input="$emit('input', $event)"
    @focusin.native="dateActive = true"
    @focusout.native="dateActive = false"
  />
  <span v-if="suffix" class="suffix">
    {{ suffix }}
  </span>
  <label
    class="p-date-title"
    :class="{ 'active': dateActive || showLabel }"
  >
    {{ placeholder }}
  </label>
  <ErrorMessage
    v-if="error"
    :errorMessage="error"
  />
</div>
</template>

<script>
import ErrorMessage from '@/components/widget/ErrorMessage.vue';

export default {
  name: 'p-date',
  components: {
    ErrorMessage,
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: null,
    },
    showClear: Boolean,
    suffix: {
      type: String,
      default: null,
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
    showLabel: Boolean,
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dateActive: false,
    };
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.p-date-wrap {
  position: relative;
  .p-date {
    width: 100%;
    margin: 8px 0 0;
    input {
      @include input;
      margin-top: 0;
      height: 45px;
      cursor: pointer;
      background: url('~@/static/img/icons/ic_cal.svg');
      background-repeat: no-repeat;
      background-position: 85% 40%;
      background-size: 24px 24px;
      &::placeholder {
        color: $black-light;
        opacity: 1;
      }
    }
    &.no-suffix input {
      background-position: 99% 40%;
    }
  }
  .suffix {
    @include subtitle;
    color: $black-light;
    font-size: 12px;
    position: absolute;
    top: 13px;
    right: 10px;
  }

  .p-date-title {
    @include label;
  }
  .p-date-title.active,
  input:focus ~ label {
    @include label_active;
  }
  .vdp-datepicker__clear-button {
    position: absolute;
    left: 114px;
    z-index: 1000;
    top: 10px;
  }
  @media (max-width: $tablet-width) {
    .p-date {
      input {
        background-position: 75% 40%;
      }
    }
  }
}
</style>
