<template>
<div v-if="project" class="dashboard-timeline-wrap">
  <DashboardHeader
    :tab="$t('dashboard.timeline.title')"
    :previewCode="project.preview_code"
    :crowdfunding="project.crowdfunding"
  />
  <div class="container">
    <div class="dashboard-content">
      <h1>{{ $t('dashboard.timeline.page_title') }}</h1>
      <div class="timeline-desc">
        {{ $t('dashboard.timeline.page_desc') }}
      </div>
      <div class="timeline-link">
        {{ $t('dashboard.timeline.link') }}
      </div>
    </div>
  </div>
  <div class="timeline-wrap">
    <div class="container">
      <div class="timeline-content dashboard-content">
        <div class="timeline-left">
          <TimelineMilestones
            :project="project"
            @milestone-edit="editMilestone"
            @milestone-delete="confirmDeleteMilestone"
          />
          <button
            v-if="addMilestoneValid"
            class="timeline-left-add"
            @click="handleOpenModal"
          >
            {{ $t('dashboard.timeline.button') }}
          </button>
          <p v-else class="p_info">
            {{ $t('dashboard.timeline.milestones.add_milestone_complete') }}
          </p>
        </div>
        <div class="timeline-right">
          <h3>{{ $t('dashboard.timeline.right_title') }}</h3>
          <div class="timeline-right-desc">
            {{ $t('dashboard.timeline.right_desc') }}
          </div>
          <div class="timeline-right-item">
            <div>{{ $t('dashboard.timeline.project_fee') }}</div>
            <Info />
            <div>{{ $tc('dashboard.timeline.fee', maxFee) }}</div>
          </div>
          <div class="timeline-right-item">
            <div>{{ $t('discount') }}</div>
            <Info />
            <div class="discount-percent">
              {{ $tc('percent', maxFee - projectFee) }}
            </div>
          </div>
          <div class="timeline-right-total">
            <div>
              <h4>{{ $t('dashboard.timeline.total_fee') }}</h4>
              <h5>{{ $t('dashboard.timeline.total_desc') }}</h5>
            </div>
            <h4>{{ $tc('dashboard.timeline.fee', projectFee) }}</h4>
          </div>
          <div class="timeline-right-protection">
            <div class="protection-title">
              <img :src="require('@/static/img/art_icons/backer_protection.png')">
              <h4>
                {{ $tc('project.campaign.protection', project.reserve_percent) }}
              </h4>
            </div>
            <div class="protection-text">
              {{ $t('dashboard.timeline.milestones.protection_text') }}
            </div>
            <div class="learn-more">
              {{ $t('learn_more') | uppercase }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DashboardFooter :label="$t('dashboard.account.title')" link="dashboard-account" />
  <MilestoneModal
    v-if="showMilestoneModal"
    :selectedMilestone="selectedMilestone"
    :fundsRaised="project.total_pledged"
    :fundingGoal="project.funding_goal || 0"
    :milestones="milestones"
    @close-modal="handleCloseModal"
    @refresh-project-data="getProjectData"
  />
  <PromptModal
    v-if="confirmDeleteOpen"
    :title="$t('dashboard.timeline.milestones.confirm_delete')"
    :description="$t('dashboard.timeline.milestones.delete_text')"
    :submitText="$t('confirm')"
    :confirmAnimating="deleteAnimating"
    @close-modal="confirmDeleteOpen = false"
    @submit="deleteMilestone"
  />
</div>
</template>

<script>
import * as api from '@/utils/api';
import DashboardHeader from '@/components/builder/DashboardHeader.vue';
import DashboardFooter from '@/components/builder/DashboardFooter.vue';
import TimelineMilestones from '@/components/builder/TimelineMilestones.vue';
import Info from '@/components/svg/Info.vue';
import MilestoneModal from '@/components/builder/MilestoneModal.vue';
import PromptModal from '@/components/widget/PromptModal.vue';
import { listingFee, reservePercent } from '@/utils/objectUtils';

export default {
  name: 'dashboard-timeline',
  components: {
    DashboardHeader,
    DashboardFooter,
    TimelineMilestones,
    Info,
    MilestoneModal,
    PromptModal,
  },
  data() {
    return {
      project: null,
      staleTimer: null,
      stale: new Set(),
      showMilestoneModal: false,
      selectedMilestone: null,
      confirmDeleteOpen: false,
      milestonePendingDelete: null,
      deleteAnimating: false,
    };
  },
  computed: {
    addMilestoneValid() {
      return reservePercent(this.milestones) <= 95;
    },
    milestones() {
      if(!this.project) {
        return [];
      }
      return this.project.milestones;
    },
    projectFee() {
      return listingFee(reservePercent(this.milestones));
    },
    maxFee() {
      return 5;
    },
  },
  methods: {
    editMilestone(milestone) {
      this.selectedMilestone = milestone;
      this.handleOpenModal();
    },
    confirmDeleteMilestone(milestone) {
      this.milestonePendingDelete = milestone;
      this.confirmDeleteOpen = true;
    },
    async deleteMilestone() {
      this.deleteAnimating = true;
      try {
        await api.deleteMilestone({
          projectId: this.project.id,
          milestoneId: this.milestonePendingDelete.id,
        });
        await this.getProjectData();
        this.confirmDeleteOpen = false;
      } catch(e) {
        console.log('Fail to delete milestone', e);
      }
      this.deleteAnimating = false;
    },
    async getProjectData() {
      try {
        this.project = await api.getCreatorProjectDetail(this.$route.params.id);
      } catch(err) {
        console.log('Error getting project data: ', err);
      }
    },
    handleOpenModal() {
      this.showMilestoneModal = true;
    },
    handleCloseModal() {
      this.showMilestoneModal = false;
      this.selectedMilestone = null;
    },
  },
  async created() {
    this.getProjectData();
  },
};

</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.dashboard-timeline-wrap {
  h1 {
    margin-bottom: 8px;
  }
  .timeline-desc {
    max-width: 500px;
  }
  .timeline-link {
    @include link;
    color: $main-blue;
    margin-top: 24px;
  }
  .timeline-wrap {
    margin-top: 48px;
    background-color: $grey-lighter;
    border-top: 1px solid $border;
  }
  .timeline-content {
    display: flex;
    padding-top: 64px;
    padding-bottom: 64px;
    .timeline-left {
      flex-grow: 1;
      box-sizing: border-box;
      padding-right: 80px;
      .timeline-left-add {
        @include black-button($size: large);
        width: 100%;
      }
    }
    .timeline-right {
      margin-left: auto;
      min-width: 320px;
      max-width: 320px;
      box-sizing: border-box;
      padding-left: 32px;
      border-left: 1px solid $border-light;
      .timeline-right-desc {
        @include p_small;
        margin: 16px 0 0;
        padding-bottom: 24px;
        max-width: 280px;
        border-bottom: 1px solid $border-light;
      }
      .timeline-right-item {
        display: flex;
        height: 56px;
        align-items: center;
        border-bottom: 1px solid $border-light;
        div {
          @include h5;
          &:first-child {
            color: $grey;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: auto;
          }
        }
        .discount-percent {
          color: $main-blue;
        }
      }
      .timeline-right-total {
        display: flex;
        margin-top: 24px;
        > h4 {
          margin-left: auto;
        }
        h5 {
          color: $grey;
        }
      }
      .timeline-right-protection {
        background-color: $grey-lightest;
        margin-top: 24px;
        padding: 24px 16px;
        text-align: center;
        .protection-title {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 32px;
            margin-right: 8px;
            position: relative;
            top: -3px;
          }
        }
        .protection-text {
          @include p_small;
          margin: 12px 0 16px;
          text-align: left;
        }
      }
    }
  }
}
</style>
