<template>
<transition name="fade">
  <div class="reward-modal-wrap">
    <div class="container">
      <ModalHeader quitText="Cancel" @close-modal="checkConfirm" />
      <div class="reward-modal-content">
        <div class="reward-modal-title">
          <div v-if="selectedReward" class="reward-modal-edit-title">
            <div />
            <h2>{{ $t(`${rewardsKey}.buttons.edit`) }}</h2>
            <div @click="handleDelete">
              {{ $t(`dashboard.${rewardsKey}.modal.titles.delete_reward`) }}
            </div>
          </div>
          <h2 v-else>
            {{ $t(`dashboard.${rewardsKey}.modal.titles.new_reward`) }}
          </h2>
        </div>
        <div class="reward-modal-body">
          <TitledInput
            v-model="dataValue.title"
            class="reward-title"
            :title="$t(`dashboard.${rewardsKey}.modal.labels.reward_title`)"
            :suffix="dataValue.title ? `${dataValue.title.length}/40` : '40'"
            :maxLength="40"
            :errorMessage="errors.title"
            @input="handleFormInput('title', $event)"
          />
          <TitledInput
            v-model="dataValue.description"
            class="reward-description"
            :title="$t('dashboard.rewards.modal.labels.description')"
            :suffix="dataValue.description ? `${dataValue.description.length}/150` : '150'"
            :maxLength="150"
            :rows="4"
            :errorMessage="errors.description"
            @input="handleFormInput('description', $event)"
          />
          <TitledInput
            v-if="dataValue.variants.length === 1 && !dataValue.is_donation"
            :value="rewardPrice"
            class="reward-price"
            type="number"
            :hasIcon="true"
            :suffix="currency"
            :errorMessage="errors.price_usd"
            @input="setRewardPrice"
          />
          <TitledInput
            v-model="dataValue.backer_limit"
            type="number"
            :errorMessage="errors.backer_limit"
            :title="$t('dashboard.rewards.modal.labels.quantity_available')"
            :suffix="$t('dashboard.rewards.modal.labels.optional')"
            class="reward-quantity"
            @input="handleFormInput('backer_limit', $event)"
          />
          <TitledDate
            :value="endDate"
            :placeholder="$t('dashboard.rewards.modal.labels.delivery_time')"
            format="M/yyyy"
            :showClear="true"
            :showLabel="!!dataValue.delivery_time"
            :suffix="!dataValue.needs_shipping ? $t('dashboard.rewards.modal.labels.optional') : null"
            :disabledDates="disabledDates"
            :error="errors.delivery_time"
            @input="handleDateSelect"
          />

          <div v-if="dataValue.variants.length === 1" class="reward-image">
            <div class="reward-field-title">
              <h4>{{ $t(`dashboard.${rewardsKey}.modal.labels.reward_image`) }}</h4>
              <span class="reward-field-tip">{{ $t('dashboard.rewards.modal.labels.optional') }}</span>
            </div>

            <ImageUpload
              id="reward-main"
              class="reward-image-upload"
              :loading="imageLoading"
              @file-select="variantImageUpload($event, 0)"
            >
              <div class="image-upload-content">
                <div class="image-preview">
                  <img
                    v-if="dataValue.variants[0].image.url"
                    class="image-display"
                    :src="dataValue.variants[0].image.url"
                  >
                  <Upload v-else color="#2E40EA" />
                </div>
                <div class="image-info">
                  <h4>{{ $t('widgets.image_upload.drag_drop') }}</h4>
                  <div>{{ $t('widgets.image_upload.browse_file') }}</div>
                </div>
              </div>
            </ImageUpload>
            <div v-html="$t('dashboard.rewards.modal.labels.reward_note')" />
            <div
              v-if="dataValue.variants[0].error"
              class="reward-error"
            >
              {{ dataValue.variants[0].error }}
            </div>
          </div>

          <div v-if="!selectedReward || !dataValue.is_donation" class="hr" />
          <div v-if="!selectedReward" class="reward-donation-check">
            <div class="reward-donation-title">
              {{ $t('dashboard.rewards.modal.labels.donation_text') }}
            </div>
            <Checkbox
              :item="{
                label: $t('dashboard.rewards.modal.labels.is_donation'),
                checked: dataValue.is_donation,
              }"
              :onCheck="checked => dataValue.is_donation = checked"
            />
          </div>
          <RewardModalVariants
            v-if="!dataValue.is_donation"
            :variants="dataValue.variants"
            @add-variant="addVariant"
            @set-variant="setVariant"
            @set-variants="setVariants"
            @variant-image-upload="variantImageUpload"
            @remove-variant="removeVariant"
            @check-default-variant="checkDefaultVariant"
          />
          <div class="hr" />

          <div class="reward-shipping">
            <div class="reward-shipping-check">
              <Checkbox
                :item="{
                  label: $t('dashboard.rewards.modal.labels.shipping_reward'),
                  checked: dataValue.needs_shipping,
                }"
                :onCheck="checkNeedsShipping"
              />
            </div>
            <div v-if="dataValue.needs_shipping" class="reward-shipping-info">
              <div class="reward-shipping-desc">
                <p>{{ $t('dashboard.rewards.modal.labels.shipping_desc') }}</p>
                <div
                  v-if="shippingCopyRewards.length"
                  class="copy-shipping link "
                  @click="openRewardShippingCopy"
                >
                  {{ $t('dashboard.rewards.modal.labels.copy_from_reward') }}
                  <div v-if="showRewardsDropdown" class="shipping-dropdown">
                    <div
                      v-for="(item, index) in shippingCopyRewards"
                      :key="index"
                      class="dropdown-items"
                      @click="copyShippingFromReward(item)"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="reward-options-list">
                <div
                  v-for="(item, index) in shippingCostList"
                  :key="index"
                  class="reward-options-list-item"
                >
                  <TitledInput
                    :value="item.usd_price / 100"
                    class="input-plus-price"
                    placeholder="0.00"
                    prefix="$"
                    type="number"
                    @input="dataValue.shipping_cost[item.alpha3] = $event * 100"
                  />
                  <TitledSelect
                    :value="item"
                    label="name"
                    :searchable="true"
                    :options="shippingCountries"
                    @input="replaceShippingCountry(item, $event)"
                  />
                  <div
                    v-if="shippingCostList.length > 1"
                    class="btn-remove"
                    @click="removeShippingCost(item.alpha3)"
                  >
                    <img
                      class="img-remove"
                      :src="require('@/static/img/icons/ic_close.png')"
                    >
                  </div>
                </div>
              </div>
              <button class="btn-add" @click="addDestination">
                {{ $t('dashboard.rewards.modal.buttons.add_destination') }}
              </button>
            </div>
          </div>

          <div class="reward-submit">
            <div
              v-if="rewardError"
              class="reward-error"
            >
              {{ rewardError }}
            </div>
            <PButton
              v-if="selectedReward"
              class="btn-reward"
              :disabled="!isValid"
              :animate="saving"
              @click="updateReward"
            >
              {{ $t(`dashboard.${rewardsKey}.modal.buttons.update_reward`) }}
            </PButton>
            <PButton
              v-else
              class="btn-reward"
              :disabled="!isValid"
              :animate="saving"
              @click="addReward"
            >
              {{ $t(`${rewardsKey}.buttons.add`) }}
            </PButton>
          </div>
        </div>
      </div>
    </div>
    <PromptModal
      v-if="confirmModalOpen"
      :title="$t('modal.confirm_cancel')"
      :description="$t('modal.exit')"
      :submitText="$t('modal.exit_anyway')"
      @close-modal="confirmModalOpen = false"
      @submit="onConfirm"
    />
    <PromptModal
      v-if="deleteModalOpen"
      :title="$t('dashboard.rewards.modal.titles.delete_reward')"
      :description="$t('dashboard.rewards.modal.labels.delete')"
      :submitText="$t('confirm')"
      @close-modal="deleteModalOpen = false"
      @submit="onDeleteReward"
    />
  </div>
</transition>
</template>

<script>
import moment from 'moment';
import ModalHeader from '@/components/widget/ModalHeader';
import RewardModalVariants from '@/components/builder/RewardModalVariants';
import TitledDate from '@/components/widget/TitledDate';
import TitledInput from '@/components/widget/TitledInput';
import TitledSelect from '@/components/widget/TitledSelect';
import Checkbox from '@/components/widget/Checkbox';
import ImageUpload from '@/components/widget/ImageUpload';
import PromptModal from '@/components/widget/PromptModal';
import Upload from '@/components/svg/Upload';
import PButton from '@/components/widget/PButton';
import { validateLength } from '@/utils/stringUtils';
import { CountriesArray, CountryCodeMap } from '@/utils/constants';
import { isEquivalent } from '@/utils/objectUtils';
import FormMixin from '@/mixins/Form';
import * as api from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';

const emptyReward = () => ({
  title: '',
  description: '',
  backer_limit: null,
  delivery_time: null,
  is_donation: false,
  needs_shipping: false,
  shipping_cost: null,
  variants_default: null,
  variants: [{
    id: null,
    image: {},
    name: null,
    price_usd: 100,
    default: false,
  }],
  variants_order: [null],
});

const prepareRewardData = (reward) => {
  const shipping = reward.needs_shipping && reward.shipping_cost;
  const rewardData = {
    backer_limit: reward.backer_limit || 0,
    delivery_time: reward.delivery_time,
    description: { en: reward.description },
    title: { en: reward.title },
    usd_price: reward.variants[0].price_usd,
    needs_shipping: reward.needs_shipping,
    is_donation: reward.is_donation,
  };
  if(shipping) {
    rewardData.shipping_cost = shipping;
  }
  return rewardData;
};

export default {
  name: 'reward-modal',
  components: {
    ModalHeader,
    RewardModalVariants,
    TitledDate,
    TitledInput,
    TitledSelect,
    Checkbox,
    Upload,
    ImageUpload,
    PromptModal,
    PButton,
  },
  mixins: [FormMixin],
  props: {
    selectedReward: {
      type: Object,
      default: () => null,
    },
    rewardsKey: {
      type: String,
      default: 'rewards',
    },
    formName: {
      type: String,
      default: 'project_rewards',
    },
    rewards: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataValue: emptyReward(),
      rules: {
        title: {
          required: true,
          validator: val => validateLength(val, { minLength: 3 }),
        },
        backer_limit: {
          validator: Number.isInteger,
        },
        description: {
          required: true,
          validator: val => validateLength(val, { minLength: 3 }),
        },
      },
      // Special case for price of first reward variant
      priceRule: {
        required: true,
        validator: Number.isInteger,
      },
      deletedVariantIds: [],
      projectId: null,
      rewardId: null,
      saving: false,
      imageLoading: false,
      image: null,
      rewardError: null,
      confirmModalOpen: false,
      deleteModalOpen: false,
      rewardModified: false,
      variantsModified: false,
      imageModified: false,
      showRewardsDropdown: false,
      disabledDates: {
        to: moment().toDate(),
      },
    };
  },
  computed: {
    variants() {
      return this.dataValue.variants.slice(1);
    },
    variantsDefaultId() {
      return this.dataValue.variants.reduce((prev, cur) => (
        cur.default ? cur.id : prev), null);
    },
    rewardImage() {
      if(this.image) {
        if(this.image.url) return this.image.url;
        return URL.createObjectURL(this.image);
      }
      return null;
    },
    rewardPrice() {
      const price = this.dataValue.variants[0].price_usd;
      if(price === null) {
        return null;
      }
      return price / 100;
    },
    shippingCopyRewards() {
      let { rewards } = this;
      if(this.selectedReward) {
        rewards = rewards.filter(reward => reward.id !== this.selectedReward.id);
      }
      return rewards;
    },
    shippingCountries() {
      const currentShipping = this.dataValue.shipping_cost || {};
      return CountriesArray.filter(country => !(country.alpha3 in currentShipping));
    },
    shippingCostList() {
      return Object.keys(this.dataValue.shipping_cost || {})
        .map(key => ({
          ...CountryCodeMap[key],
          usd_price: this.dataValue.shipping_cost[key],
        }));
    },
    endDate() {
      if(this.dataValue.delivery_time) {
        return moment.unix(this.dataValue.delivery_time).toDate();
      }
      return null;
    },
  },
  watch: {
    dataValue: {
      handler() { this.rewardError = null },
      deep: true,
    },
  },
  methods: {
    openRewardShippingCopy() {
      this.showRewardsDropdown = !this.showRewardsDropdown;
    },
    copyShippingFromReward(reward) {
      this.dataValue.shipping_cost = {
        ...(reward.shipping_cost || {}),
      };
    },
    replaceShippingCountry(item, newCountry) {
      delete this.dataValue.shipping_cost[item.alpha3];
      this.$set(this.dataValue.shipping_cost, newCountry.alpha3, item.usd_price);
    },
    handleDateSelect(val) {
      this.dataValue.delivery_time = moment(val).unix();
      this.errors.delivery_time = null;
    },
    setRewardPrice(value) {
      if(Number.isNaN(value)) {
        this.dataValue.variants[0].price_usd = null;
      } else {
        this.dataValue.variants[0].price_usd = value * 100;
      }
      this.validateRule('price_usd', value, this.priceRule);
    },
    async variantImageUpload(imgFile, variantIndex) {
      const oldVariant = this.dataValue.variants[variantIndex];
      let updatedVariant = { ...oldVariant };
      if(!imgFile) {
        updatedVariant.error = this.$t('errors.default');
      } else if(imgFile.size > 1048576) {
        // 1MB file size limit
        updatedVariant.error = this.$tc('errors.IMAGE_SIZE', 1);
      } else {
        const projectId = this.$route.params.id;
        try {
          // Try updating main reward image immediately
          if(this.selectedReward && variantIndex === 0) {
            this.imageLoading = true;
            const { data: { variant } } = await api.updateVariantImage({
              projectId,
              rewardId: this.rewardId,
              variantId: oldVariant.id,
              file: imgFile,
            });
            updatedVariant = variant;
            this.imageLoading = false;
          } else {
            const image = {
              url: URL.createObjectURL(imgFile),
              type: imgFile.type,
              name: imgFile.name,
            };
            updatedVariant.image = image;
          }
        } catch(err) {
          updatedVariant.error = firstErrorKey(err.errors);
        }
      }
      this.$set(this.dataValue.variants, variantIndex, updatedVariant);
    },
    addVariant(variant) {
      this.ensureDefaultVariant();
      this.dataValue.variants.push(variant);
    },
    setVariant(variant, index) {
      this.$set(this.dataValue.variants, index, variant);
      this.validateVariants();
    },
    setVariants(variants) {
      this.dataValue.variants = variants;
    },
    removeVariant(index) {
      // If the variant already exists, mark it for deletion
      if('id' in this.dataValue.variants[index]) {
        this.deletedVariantIds.push(this.dataValue.variants[index].id);
      }
      this.dataValue.variants.splice(index, 1);
      this.ensureDefaultVariant();
    },
    ensureDefaultVariant() {
      if(this.dataValue.variants.length === 1) {
        this.dataValue.variants[0].default = true;
      }
    },
    addDestination() {
      const newCountry = this.shippingCountries[0].alpha3;
      const cost = this.dataValue.shipping_cost['*'] || 1000;
      this.$set(this.dataValue.shipping_cost, newCountry, cost);
    },
    removeShippingCost(alpha3) {
      this.$delete(this.dataValue.shipping_cost, alpha3);
    },
    async updateVariant(variant) {
      await api.updateRewardVariant({
        projectId: this.projectId,
        rewardId: this.rewardId,
        variantId: variant.id,
        variant: {
          price_usd: variant.price_usd,
          // Hack for when the user deletes the second-to-last variant, and the remaining
          // variant has no name.
          name: { en: variant.name || this.dataValue.title },
        },
      });
    },
    async createVariant(variant) {
      const { data: { variant: { id: variantId } } } = await api.createRewardVariant({
        projectId: this.projectId,
        rewardId: this.rewardId,
        variant: {
          price_usd: variant.price_usd,
          // Hack for when the user deletes the second-to-last variant, and the remaining
          // variant has no name.
          name: { en: variant.name || this.dataValue.title },
        },
      });
      return variantId;
    },
    validateVariants() {
      let variantsError = false;
      if(this.dataValue.variants.length > 1) {
        // Make sure all variants have a name and price
        this.dataValue.variants.forEach((variant) => {
          const { name, price_usd: variantPrice } = variant;
          if(!name) {
            this.$set(variant, 'error', this.$t(
              'errors.VALUE_MISSING',
              { label: this.$t('dashboard.rewards.modal.labels.variant_name').toLowerCase() },
            ));
            variantsError = true;
          } else if(!variantPrice && (variantPrice !== 0)) {
            this.$set(variant, 'error', this.$t(
              'errors.VALUE_MISSING',
              { label: this.$t('form.project_rewards.labels.price_usd').toLowerCase() },
            ));
            variantsError = true;
          } else {
            this.$set(variant, 'error', null);
          }
        });
      }
      return variantsError;
    },
    validateReward() {
      const price = this.dataValue.variants[0].price_usd;
      const variantsError = this.validateVariants();
      if(this.dataValue.needs_shipping && !this.dataValue.delivery_time) {
        this.errors.delivery_time = this.$t(
          'errors.VALUE_MISSING',
          { label: this.$t('dashboard.rewards.modal.labels.delivery_time').toLowerCase() },
        );
      }
      if(!this.validateAll() || !this.validateRule('price_usd', price, this.priceRule) || variantsError || this.errors.delivery_time) {
        this.rewardError = this.$t('dashboard.rewards.modal.error');
        return false;
      }
      return true;
    },
    async addReward() {
      if(!this.validateReward()) {
        return;
      }
      this.saving = true;

      try {
        const { data: { reward } } = await api.createReward({
          projectId: this.projectId,
          reward: prepareRewardData(this.dataValue),
        });
        this.rewardId = reward.id;
        this.dataValue.variants[0].id = reward.variants[0].id;
        if('name' in this.dataValue.variants[0].image) {
          await this.saveVariantImage(this.dataValue.variants[0]);
        }
        if(this.dataValue.variants.length > 1) {
          // Update the first variant because the default reward API endpoint doesn't support creation
          await this.updateVariant(this.dataValue.variants[0]);

          for(let i = 1; i < this.dataValue.variants.length; i += 1) {
            /* eslint-disable no-await-in-loop */
            const newVariant = this.dataValue.variants[i];
            newVariant.id = await this.createVariant(newVariant);
            if('name' in newVariant.image) {
              await this.saveVariantImage(newVariant);
            }
            /* eslint-enable no-await-in-loop */
          }
        }
        if(this.variantsDefaultId) {
          await api.updateReward({
            projectId: this.projectId,
            rewardId: this.rewardId,
            reward: { variants_default: this.variantsDefaultId },
          });
        }
        this.$emit('reward-added', reward);
      } catch(err) {
        console.log('Error in add reward: ', err);
      }
      this.saving = false;
    },
    handleDelete() {
      this.deleteModalOpen = true;
    },
    async onDeleteReward() {
      try {
        const projectId = this.$route.params.id;
        await api.deleteReward({ projectId, rewardId: this.rewardId });
        this.$emit('reward-deleted', this.rewardId);
        this.$emit('close-modal');
      } catch(err) {
        console.log('error in delete reward: ', err);
      }
    },

    async updateReward() {
      if(!this.validateReward()) {
        return;
      }
      this.saving = true;
      try {
        const variantCount = this.dataValue.variants.length;
        for(let i = 0; i < variantCount; i += 1) {
          const originalVariant = this.selectedReward.variants[i] || {};
          const variant = this.dataValue.variants[i];
          // Possible modified variant
          /* eslint-disable no-await-in-loop */
          if('id' in variant) {
            if(!isEquivalent(variant, originalVariant)) {
              await this.updateVariant(variant);
            }
          // New variant
          } else {
            variant.id = await this.createVariant(variant);
          }
          // If there is an image to upload with the variant
          if('name' in variant.image) {
            // Get file object from local blob for upload
            await this.saveVariantImage(variant);
          }
          /* eslint-enable no-await-in-loop */
        }

        // Handle variant deletions after updates
        // Less efficient, but avoids temporarily removing the last variant
        await Promise.all(this.deletedVariantIds.map(vid => (
          api.deleteRewardVariant({
            projectId: this.projectId,
            rewardId: this.rewardId,
            variantId: vid,
          })
        )));

        if(this.isModified()) {
          const reward = prepareRewardData(this.dataValue);
          reward.variants_default = this.variantsDefaultId || this.dataValue.variants[0].id;
          reward.variants_order = this.dataValue.variants.map(v => v.id);
          await api.updateReward({
            projectId: this.projectId,
            rewardId: this.rewardId,
            reward,
          });
        }

        this.$emit('reward-updated');
      } catch(err) {
        console.log('error in update reward: ', err);
      }
      this.saving = false;
    },
    checkConfirm() {
      if(!this.isModified()) {
        this.$emit('close-modal');
      } else {
        this.confirmModalOpen = true;
      }
    },
    onConfirm() {
      this.$emit('close-modal');
    },
    checkDefaultVariant(index, checked) {
      if(checked) {
        this.dataValue.variants.forEach((v) => { v.default = false });
        this.$set(this.dataValue.variants, index, { ...this.dataValue.variants[index], default: true });
      }
    },
    checkNeedsShipping(checked) {
      if(checked) {
        if(Object.keys(this.dataValue.shipping_cost || {}).length === 0) {
          this.dataValue.shipping_cost = { '*': 1000 };
        }
      } else {
        this.dataValue.shipping_cost = null;
      }
      this.dataValue.needs_shipping = checked;
    },
    onModifiedReward() {
      this.rewardModified = true;
    },
    onModifiedVariants() {
      this.variantsModified = true;
    },
    onModifiedImage() {
      this.imageModified = true;
    },
    promptUnsaved(ev) {
      if(this.isModified()) {
        ev.preventDefault();
        // Note: Most modern browsers no longer allow custom messages
        const exitWarning = this.$t('modal.exit');
        ev.returnValue = exitWarning;
      }
      return false;
    },
    async saveVariantImage(variant) {
      const file = await fetch(variant.image.url)
        .then(r => r.blob())
        .then(blobFile => new File([blobFile], variant.image.name, { type: variant.image.type }));
      await api.updateVariantImage({
        projectId: this.projectId,
        rewardId: this.rewardId,
        variantId: variant.id,
        file,
      });
    },
    // Explicit equality comparison to avoid various issues with watching for deep changes
    isModified() {
      const original = this.selectedReward || emptyReward();
      const newData = this.dataValue;
      const originalOrder = original.variants_order || [];
      const newOrder = newData.variants_order || [];
      return original.variants.length !== newData.variants.length
        || (original.backer_limit || 0) !== (newData.backer_limit || 0)
        || original.needs_shipping !== newData.needs_shipping
        || original.delivery_time !== newData.delivery_time
        || original.description !== newData.description
        || original.title !== newData.title
        || original.variants_default !== this.variantsDefaultId
        || originalOrder.length !== newOrder.length
        || originalOrder.some((id, i) => id !== newOrder[i])
        || newData.variants.some((variant, i) => !isEquivalent(variant, original.variants[i]))
        || !isEquivalent(newData.shipping_cost, original.shipping_cost);
    },
  },
  created() {
    window.addEventListener('beforeunload', this.promptUnsaved);
    if(this.selectedReward) {
      this.rewardId = this.selectedReward.id;
      this.dataValue = JSON.parse(JSON.stringify(this.selectedReward));
      this.dataValue.backer_limit = this.dataValue.backer_limit || null;
    }
    this.projectId = this.$route.params.id;
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.promptUnsaved);
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.reward-modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-color: $white;
  z-index: 2000;
  padding: 32px 50px 72px;
  > .container {
    overflow: visible;
  }
  .img-upload-error {
    @include h5;
    margin-top: 8px;
    color: $main-red;
  }

  .reward-error {
    @include button_small_text;
    color: $main-orange;
    padding-top: 10px;
  }
  .reward-modal-content {
    max-width: 645px;
    margin: auto;
  }
  .reward-modal-title {
    text-align: center;
    margin: 24px 0 40px;
  }
  .reward-modal-edit-title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    > div {
      @include button_small_text;
      color: $main-orange;
      cursor: pointer;
    }
  }
  .reward-donation-check {
    .reward-donation-title {
      @include h4;
    }
    .p-checkbox {
      .checkmark {
        margin-top: 12px;
      }
      .checkbox-text {
        @include p_small;
        font-weight: normal;
        margin-top: 8px;
      }
    }
  }
  .reward-field-title {
    margin-top: 32px;
    display: flex;

    .reward-field-tip {
      @include subheading;
      color: $black-light;
      text-transform: uppercase;
      margin-left: 16px;
    }
  }
  .reward-field-desc {
    @include p_small;
    color: $black-med;
    margin-top: 8px;
  }
  .reward-quantity .suffix {
    font-size: 12px;
  }
  .img-upload-wrap {
    .image-upload-content {
      display: flex;
      cursor: pointer;
    }
    .image-preview, .variant-image-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $border-light;
    }
    .image-preview {
      width: 160px;
      height: 160px;
      min-width: 160px;
      .image-display {
        width: 160px;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }
    .variant-image-preview {
      width: 46px;
      height: 46px;
      min-width: 46px;
    }
    .image-info {
      display: flex;
      padding: 24px;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      div {
        color: $main-blue;
        @include button_small_text;
        margin-top: 8px;
      }
    }
  }
  .hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .reward-image {
    .reward-field-title {
      margin-bottom: 24px;
    }
    .img-upload-button {
      display: none;
    }
    .img-upload-spinner {
      position: absolute;
      top: 0;
      left: -1px;
    }
    span {
      display: inline-block;
      &:not(:last-child) {
        padding-right: 16px;
      }
    }
  }

  .reward-shipping {
    .p-checkbox-wrap {
      padding: 10px 16px;
      border: 1px solid $border-light;
    }
    .reward-shipping-check {
      cursor: pointer;
    }
    .reward-shipping-desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 24px 0;
      p {
        @include p_small;
        color: $black-med;
        margin: 0;
      }
      .link {
        @include link;
        margin-top: 4px;
      }
    }
  }

  .reward-options-list {
    margin-bottom: 16px;
    .reward-options-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -1px;
      .p-input-wrap,
      .p-select-wrap,
      .btn-remove,
      .default-location {
        margin: 0;
        box-sizing: border-box;
      }
      .p-input-wrap input {
        border-right: none;
        &.input-plus-price {
          border-left: none;
        }
      }
      .p-input-wrap,
      .p-select-wrap,
      .img-upload-wrap {
        width: 100%;
      }
      .img-upload-wrap {
        height: 48px;
        border: 1px solid $border-light;
        &.dragging {
          border: 1px dashed $main-blue;
          z-index: 10;
        }
        .image-preview {
          width: 48px;
          height: 48px;
          min-width: 48px;
        }
        .image-info {
          display: flex;
          padding: 8px;
          width: 100%;
          justify-content: center;
          text-align: center;
        }
      }
      .input-plus-price {
        width: 130px;
        min-width: 130px;
      }
      .default-location {
        @include subtitle;
        border: 1px solid $border-light;
        width: 100%;
        padding: 11px 12px 13px;
        height: 48px;
      }
      .btn-remove {
        width: 48px;
        min-width: 48px;
        height: 48px;
        border: 1px solid $border-light;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .img-upload-button {
        display: none;
      }
      .image-upload-spinner {
        position: absolute;
        top: 48px;
        left: 48px;
      }
    }
  }

  .btn-add {
    @include button($main-blue, small, 'secondary');
    width: 100%;
  }
  .reward-submit {
    margin-top: 16px;
    .btn-reward {
      @include button($main-black, large);
      width: 100%;
      margin-top: 16px;
      margin-bottom: 72px;
    }
  }
  .shipping-dropdown {
    position: absolute;
    background: $white;
    z-index: 99;
    border: 1px solid $border-light;
    border-bottom: none;
    min-width: 220px;
    right: 0;
    top: 26px;
    .dropdown-items {
      padding: 8px 12px;
      border-bottom: 1px solid $border-light;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  .copy-shipping {
    position: relative;
  }

  @media (max-width: $mobile-width) {
    padding-left: 20px;
    padding-right: 20px;

    .reward-options-list .reward-options-list-item .input-plus-price {
      width: 100px;
      min-width: 100px;
    }
  }
}
</style>
