<template>
<div v-if="project" class="overview-wrap">
  <DashboardHeader
    :showTabs="false"
    :previewCode="project.preview_code"
  />
  <div class="container">
    <div class="dashboard-content">
      <h1>{{ $t('dashboard.faq.title') }}</h1>
      <hr>
      <div class="dashboard-faq-content">
        <div class="dashboard-faq-left">
          <div class="faq-questions-title">
            {{ $t('dashboard.faq.questions') }}
          </div>
          <div v-if="faqs.length" class="faq-questions">
            <div class="faq-questions-new" @click="showQuestionModal({})">
              {{ $t('dashboard.faq.new') }}
            </div>
            <draggable
              v-model="faqs"
              class="dashboard-faq-draggable"
              @end="updateOrder"
            >
              <FAQAccordion
                v-for="(faqItem, index) in faqs"
                :key="index"
                :faq="faqItem"
                :edit="true"
                @edit="showQuestionModal(faqItem); $event.stopPropagation();"
                @delete="deleteQuestion(faqItem); $event.stopPropagation();"
              />
            </draggable>
          </div>
          <div v-else class="faq-questions-empty">
            <div class="faq-empty-text">
              {{ $t('dashboard.faq.empty') }}
            </div>
            <div class="faq-questions-new" @click="showQuestionModal({})">
              {{ $t('dashboard.faq.new') }}
            </div>
          </div>
        </div>
        <div class="dashboard-faq-right">
          <div class="dashboard-faq-info">
            {{ $t('dashboard.faq.text1') }}
          </div>
          <div class="dashboard-faq-info">
            {{ $t('dashboard.faq.text2') }}
          </div>
          <div class="dashboard-faq-info">
            {{ $t('dashboard.faq.text3') }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <FAQModal
    v-if="showNewQuestion"
    :faqItem="selectedQuestion"
    :projectId="project.id"
    :cancelText="$t('dashboard.faq.discard')"
    @saved="questionUpdated"
    @close-modal="hideQuestionModal"
  />
</div>
</template>

<script>
import draggable from 'vuedraggable';
import * as api from '@/utils/api';
import { objectIdSort } from '@/utils';
import DashboardHeader from '@/components/builder/DashboardHeader.vue';
import FAQModal from '@/components/builder/FAQModal.vue';
import FAQAccordion from '@/components/project/FAQAccordion.vue';

export default {
  name: 'dashboard-faq',
  components: {
    draggable,
    DashboardHeader,
    FAQAccordion,
    FAQModal,
  },
  data() {
    return {
      project: null,
      showNewQuestion: false,
      selectedQuestion: null,
      faqs: null,
    };
  },
  watch: {
    project(newProject) {
      const { faqs, faq_order: order } = newProject;
      this.faqs = objectIdSort(faqs || [], order);
    },
  },
  methods: {
    async deleteQuestion(item) {
      try {
        await api.deleteFAQItem({
          projectId: this.project.id,
          faqItemId: item.id,
        });
        this.getProjectData();
      } catch(e) {
        console.log(e);
      }
    },
    questionUpdated() {
      this.hideQuestionModal();
      this.getProjectData();
    },
    hideQuestionModal() {
      this.selectedQuestion = null;
      this.showNewQuestion = false;
    },
    showQuestionModal(item) {
      this.selectedQuestion = item;
      this.showNewQuestion = true;
    },
    async getProjectData() {
      try {
        this.project = await api.getCreatorProjectDetail(this.$route.params.id);
      } catch(err) {
        console.log('Error getting project data: ', err);
      }
    },
    updateOrder(e) {
      if(e.oldIndex !== e.newIndex) {
        const projectId = this.project.id;
        const order = this.faqs.map(faq => faq.id);
        api.updateProject({ projectId, projectData: { faq_order: order } });
      }
    },
  },
  created() {
    this.getProjectData();
  },
};

</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.dashboard-content {
  margin-top: 72px;
  margin-bottom: 72px;
}
.dashboard-faq-content {
  display: flex;
  flex-wrap: wrap;
  .faq-questions-new {
    @include button;
  }
  .faq-questions .faq-questions-new {
    margin: 16px 0;
  }
  .dashboard-faq-left {
    width: 60%;
    padding-right: 40px;
    .faq-questions-title {
      @include h3;
    }
    .faq-questions-empty {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: $bg-grey-light;
      padding: 48px 32px;
      margin-top: 16px;
      .faq-empty-text {
        @include h4;
        color: $grey50;
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
      }
    }
  }
  .dashboard-faq-right {
    width: 40%;
    @include p_small;
    .dashboard-faq-info {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  @media (max-width: $tablet-width) {
    .dashboard-faq-left, .dashboard-faq-right {
      width: 100%;
    }
    .dashboard-faq-right {
      padding-top: 24px;
    }
  }
}
</style>
