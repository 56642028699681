<template>
<div v-if="project" class="rewards container">
  <div class="dashboard-content">
    <h3>{{ $t(`dashboard.${rewardsKey}.rewards_title`) }}</h3>
    <div class="text">
      {{ $t(`dashboard.${rewardsKey}.rewards_text1`) }}
    </div>
    <div class="text">
      {{ $t(`dashboard.${rewardsKey}.rewards_text2`) }}
    </div>
    <div class="new-reward">
      <button class="btn-new-reward" @click="showRewardModal = true">
        {{ $t(`dashboard.${rewardsKey}.new_reward`) }}
      </button>
    </div>
  </div>
  <RewardListEdit
    :project="project"
    :rewardsInit="rewards"
    :order="order"
    :edit="true"
    @reward-edit="onRewardEdit"
    @on-order="onRewardsOrdered"
  />
  <RewardModal
    v-if="showRewardModal"
    :selectedReward="selectedReward"
    :rewardsKey="rewardsKey"
    :rewards="rewardsWithShipping"
    :currency="project.currency"
    @close-modal="hideRewardModal"
    @reward-updated="refreshProject"
    @reward-added="onRewardAdded"
    @reward-deleted="onRewardDeleted"
  />
</div>
</template>

<script>
import RewardListEdit from '@/components/builder/RewardListEdit.vue';
import RewardModal from '@/components/builder/RewardModal.vue';
import { updateProject } from '@/utils/api';

export default {
  name: 'dashboard-rewards-rewards',
  components: {
    RewardListEdit,
    RewardModal,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showRewardModal: false,
      selectedReward: null,
      rewards: this.getRewards(this.project),
      order: null,
    };
  },
  computed: {
    rewardsKey() {
      return this.project.crowdfunding ? 'rewards' : 'products';
    },
    rewardsWithShipping() {
      return this.rewards.filter(reward => reward.needs_shipping);
    },
  },
  watch: {
    project(val) {
      this.rewards = this.getRewards(val);
      this.order = [...val.rewards_order];
      if(this.selectedReward) {
        for(let i = 0; i < val.rewards.length; i += 1) {
          const reward = val.rewards[i];
          if(reward.id === this.selectedReward.id) {
            this.selectedReward = reward;
          }
        }
      }
    },
  },
  methods: {
    getRewards(project) {
      if(!project || !project.rewards) {
        return [];
      }
      return project.rewards.map(reward => (
        {
          ...reward,
          variants: reward.variants.map(variant => ({
            ...variant,
            default: variant.id === reward.variants_default,
          })),
        }
      ));
    },
    hideRewardModal() {
      this.showRewardModal = false;
      this.selectedReward = null;
    },
    refreshProject() {
      this.hideRewardModal();
      this.$emit('refresh-project');
    },
    onRewardEdit(reward) {
      this.selectedReward = reward;
      this.showRewardModal = true;
    },
    async onRewardDeleted(rewardId) {
      this.rewards = this.rewards.filter(r => r.id !== rewardId);
      this.order = this.rewards.map(r => r.id);
      await updateProject({
        projectId: this.project.id,
        projectData: { rewards_order: this.order },
      });
    },
    async onRewardAdded(reward) {
      this.rewards = [...this.rewards, reward];
      this.order = this.rewards.map(r => r.id);
      await updateProject({
        projectId: this.project.id,
        projectData: { rewards_order: this.order },
      });
      this.refreshProject();
    },
    async onRewardsOrdered(order) {
      this.order = order;
      await updateProject({
        projectId: this.project.id,
        projectData: { rewards_order: order },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import 'widgets';

.rewards {
  margin-top: 72px;
  padding-bottom: 80px;
  overflow: visible;
  .dashboard-content {
    .text {
      max-width: 500px;
      margin-top: 24px;
    }
    .new-reward {
      margin-top: 40px;
      .btn-new-reward {
        @include black-button;
      }
    }
  }
  .reward-list {
    margin-top: 48px;
  }
}
</style>
