<template>
<div v-if="keys" class="error-list-wrap">
  <transition-group name="animate-height" appear>
    <div v-for="(key, index) in keys" :key="`error${index}`" class="error-message">
      <div class="error">
        {{ $t(key) }}
      </div>
    </div>
  </transition-group>
</div>
</template>

<script>
import { errorKeys } from '@/utils/apiError';

export default {
  name: 'error-list',
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    keys() {
      return errorKeys(this.errors);
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.error-list-wrap {
  width: 100%;

  .error {
    @include error;
  }
  .error-icon {
    flex-shrink: 0;
  }
  .error-message {
    max-height: 100px;
    opacity: 1;
    transition: all 0.3s;
  }

  .animate-height-enter, .animate-height-leave-to {
    max-height: 0;
    opacity: 0;
  }
}

</style>
