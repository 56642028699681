<template>
<Modal
  :title="$t('dashboard.publish.modal.titles.storefront')"
  :submitText="$t('dashboard.publish.modal.labels.storefront')"
  @submit="$emit('submit')"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="content-paragraph">
      {{ $t('dashboard.publish.modal.labels.review1') }} <br>
      {{ $t('dashboard.publish.modal.labels.review2') }}
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';

export default {
  components: { Modal },
};
</script>

<style>
.modal-inner .content-paragraph {
  text-align: center;
  margin-top: 20px;
}
</style>

