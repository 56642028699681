<template>
<div v-if="dataValue" class="overview-wrap">
  <DashboardHeader
    :tab="$t('dashboard.overview.title')"
    :saveStatus="saveStatus"
    :previewCode="dataValue.preview_code"
    :crowdfunding="dataValue.crowdfunding"
  />
  <div class="container">
    <div class="dashboard-content">
      <h1>{{ $t('dashboard.overview.title') }}</h1>
      <Section :info="$t('dashboard.overview.main')" />
      <MarkdownEditor
        class="editor"
        :value="dataValue.description"
        :placeholder="$t('dashboard.overview.placeholder')"
        :projectId="dataValue.id"
        @input="update('description', $event)"
      />
    </div>
  </div>
  <DashboardFooter :label="$t('rewards.title')" link="dashboard-rewards" />
</div>
</template>

<script>
import DashboardHeader from '@/components/builder/DashboardHeader.vue';
import DashboardFooter from '@/components/builder/DashboardFooter.vue';
import MarkdownEditor from '@/components/widget/MarkdownEditor.vue';
import Section from '@/components/widget/Section.vue';
import AutoSaveProject from '@/mixins/AutoSaveProject';

export default {
  name: 'dashboard-overview',
  components: {
    DashboardHeader,
    Section,
    DashboardFooter,
    MarkdownEditor,
  },
  mixins: [AutoSaveProject],
  data() {
    return {
      errors: {},
    };
  },
};

</script>

<style lang="scss">
@import 'general';

.overview-wrap .dashboard-content {
  padding-bottom: 80px;

  .basics-section .section-left h4 {
    @include h3;
  }
  .editor {
    height: 600px;
    .tui-editor-contents {
      box-sizing: border-box;
      padding: 48px 10%;
    }
  }
}
</style>
