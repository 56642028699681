<template>
<div v-if="project" class="dashboard-translation-wrap container">
  <DashboardHeader
    :tab="$t('dashboard.translation.title')"
    :previewCode="project.preview_code"
    :crowdfunding="project.crowdfunding"
  />
  <div class="dashboard-content">
    <h1>{{ $t('dashboard.translation.page_title') }}</h1>
  </div>
  <div class="translation-content">
    <div class="translation-left">
      <h3 class="translation-title">
        {{ $t('dashboard.translation.page_subtitle') }}
      </h3>
      <div class="translation-desc">
        {{ $t('dashboard.translation.page_desc') }}
      </div>
      <button
        class="translation-left-add"
        @click="showTranslationModal = true"
      >
        {{ $t('dashboard.translation.button') }}
      </button>
      <div v-if="project.languages" class="translation-languages-section">
        <div
          v-for="({name, code}, index) in project.languages"
          :key="index"
          class="translation-language"
        >
          <div class="section-left">
            <h4>{{ name }} </h4>
            <div>{{ $tc('dashboard.translation.progress', 0) }}</div>
          </div>
          <div class="section-right">
            <h5 class="edit" @click="edit(code)">
              {{ $t('edit') }}
            </h5>
            <h5 class="preview">
              {{ $t('preview') }}
            </h5>
            <h5 class="remove">
              {{ $t('remove') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="translation-right">
      <div class="translation-right-desc">
        <div>{{ $t('dashboard.translation.right_desc') }}</div>
        <button class="translation-download">
          {{ $t('download') }}
        </button>
      </div>
    </div>
  </div>
  <AddTranslationModal
    v-if="showTranslationModal"
    :selected="project.languages || []"
    @update="updateLanguages"
    @close-modal="showTranslationModal = false"
  />
  <TranslationExplainerModal
    v-if="showExplainerModal"
    @close-modal="showExplainerModal = false"
  />
</div>
</template>

<script>
import * as api from '@/utils/api';
import DashboardHeader from '@/components/builder/DashboardHeader';
import AddTranslationModal from '@/components/builder/AddTranslationModal';
import TranslationExplainerModal from '@/components/builder/TranslationExplainerModal';
import { SET_PROJECT_ACTIVE_LANGUAGE } from '@/store/actions';

export default {
  name: 'dashboard-translation',
  components: {
    DashboardHeader,
    AddTranslationModal,
    TranslationExplainerModal,
  },
  data() {
    return {
      project: null,
      showTranslationModal: false,
      showExplainerModal: false,
    };
  },
  methods: {
    updateLanguages(_value) {
      // TODO -- update project languages via API
    },
    async getProjectData() {
      try {
        this.project = await api.getCreatorProjectDetail(this.$route.params.id);
        if(!this.project.languages || this.project.languages.length === 0) {
          this.showExplainerModal = true;
        }
      } catch(err) {
        console.log('Error getting project data: ', err);
      }
    },
    edit(language) {
      this.$store.commit(SET_PROJECT_ACTIVE_LANGUAGE, language);
      this.$router.push({ name: 'dashboard', params: { id: this.project.id } });
    },
  },
  async created() {
    this.getProjectData();
  },
};

</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.dashboard-translation-wrap {
  .translation-desc {
    @include p_small;
    max-width: 500px;
    margin-bottom: 50px;
  }
  .translation-wrap {
    margin-top: 136px;
  }
  .translation-content {
    display: flex;
    border-top: 1px solid $border-light;
    padding-top: 55px;
    padding-bottom: 64px;
    margin: 0 7%;
    .translation-left {
      flex-grow: 1;
      box-sizing: border-box;
      padding-right: 80px;
      .translation-title {
        margin-bottom: 24px;
      }
      .translation-left-add {
        @include black-button($size: large);
      }

      .translation-languages-section {
        margin-top: 64px;
        .translation-language {
          display: flex;
          border: 1px solid $border-lighter;
          padding: 24px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.04);
          margin-bottom: 24px;
          .section-left {
            flex-grow: 1;
          }
          .section-right {
            display: flex;
            h5 {
              margin-left: 32px;
            }
            .edit {
              color: $main-blue;
              cursor: pointer;
            }
            .preview {
              color: $grey2;
            }
            .remove {
              color: $main-orange;
            }
          }
        }
      }
    }
    .translation-right {
      margin-left: auto;
      max-width: 414px;
      .translation-right-desc {
        background: $blue-info;
        @include p_large;
        margin: 0 0 24px;
        padding: 24px;
        .translation-download {
          @include button($main-blue, full);
          margin-top: 24px;
        }
      }
    }
  }
  @media (max-width: $tablet-width) {
    .translation-content {
      flex-direction: column;
      .translation-left {
        padding-right: 0;
      }
      .translation-right {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
}
</style>
