<template>
<div class="reward-variants">
  <div class="reward-field-title">
    <h4>{{ $t('dashboard.rewards.modal.labels.variants') }}</h4>
    <span class="reward-field-tip">{{ $t('dashboard.rewards.modal.labels.optional') }}</span>
  </div>
  <p class="reward-field-desc">
    {{ $t(`dashboard.${rewardsKey}.modal.labels.variants_desc`) }}
  </p>
  <div v-if="variants.length > 1" class="reward-options-list">
    <draggable
      :value="variants"
      class="variants-draggable"
      @input="variantsOrderChanged"
    >
      <div
        v-for="(variant, index) in variants"
        :key="index"
      >
        <div class="reward-options-list-item">
          <TitledInput
            :value="variant.name"
            :placeholder="$t('dashboard.rewards.modal.labels.variant_name')"
            @input="name => $emit('set-variant', { ...variant, name }, index)"
          />
          <ImageUpload
            :id="`variant${index}`"
            @file-select="$emit('variant-image-upload', $event, index)"
          >
            <div class="image-upload-content">
              <img
                v-if="variant.image.url"
                class="variant-image-preview"
                :src="variant.image.url"
              >
              <div class="image-info">
                <div>{{ $t('dashboard.rewards.modal.labels.upload_image') }}</div>
              </div>
            </div>
          </ImageUpload>
          <TitledInput
            :value="variantPrice(variant)"
            class="input-plus-price"
            placeholder="0.00"
            type="number"
            prefix="$"
            @input="setVariantPrice(index, $event)"
          />
          <div class="variant-default">
            <Checkbox
              :item="{ checked: variant.default }"
              :onCheck="checked => $emit('check-default-variant', index, checked)"
            />
          </div>
          <div
            class="btn-remove"
            @click="$emit('remove-variant', index)"
          >
            <img
              class="img-remove"
              :src="require('@/static/img/icons/ic_close.png')"
            >
          </div>
        </div>
        <div v-if="variant.error" class="variant-error">
          {{ variant.error }}
        </div>
      </div>
    </draggable>
  </div>
  <button
    class="btn-add"
    @click="addVariant"
  >
    {{ $t('dashboard.rewards.modal.buttons.add_variation') }}
  </button>
</div>
</template>

<script>
import draggable from 'vuedraggable';
import Checkbox from '@/components/widget/Checkbox';
import ImageUpload from '@/components/widget/ImageUpload';
import TitledInput from '@/components/widget/TitledInput';

const variantTemplate = {
  image: {},
  name: null,
  price_usd: 100,
  default: false,
};

export default {
  name: 'reward-modal-variants',
  components: {
    draggable,
    Checkbox,
    ImageUpload,
    TitledInput,
  },
  props: {
    rewardsKey: {
      type: String,
      default: 'rewards',
    },
    variants: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      variantErrors: [],
    };
  },
  methods: {
    addVariant() {
      this.$emit('add-variant', { ...variantTemplate });
    },
    variantPrice(variant) {
      if(variant.price_usd === null) {
        return null;
      }
      return variant.price_usd / 100;
    },
    setVariantPrice(index, value) {
      const variant = {
        ...this.variants[index],
        price_usd: null,
      };
      if(!Number.isNaN(value)) {
        variant.price_usd = value * 100;
      }
      this.$emit('set-variant', variant, index);
    },
    variantsOrderChanged(variants) {
      this.$emit('set-variants', variants);
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.variants-draggable {
  .variant-error {
    @include button_small_text;
    color: $main-orange;
    padding: 8px 0;
  }
  .variant-default {
    height: 48px;
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    .p-checkbox {
      margin-top: 16px;
    }
  }
}
</style>
