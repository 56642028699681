<template>
<div v-if="project" class="timeline-milestones">
  <div class="timeline-milestone fixed">
    <Milestone
      :milestone="{ name: $t('dashboard.timeline.milestones.raised') }"
      :first="true"
      :hover="false"
    />
  </div>
  <div v-if="receiveFundsPercent > 0" class="timeline-milestone fixed">
    <Milestone
      :milestone="{
        name: $t('dashboard.timeline.milestones.receive'),
        description: $t('dashboard.timeline.milestones.receive_text'),
        release_percent: receiveFundsPercent,
        formattedDate: $t('dashboard.timeline.milestones.initial_date'),
      }"
      :hover="false"
    />
  </div>
  <div
    v-for="(milestone, idx) in project.milestones"
    :key="idx.toString()"
    class="timeline-milestone"
  >
    <Milestone
      :milestone="milestone"
      :last="idx === (project.milestones.length - 1)"
      :hover="true"
      :editable="true"
      @edit="$emit('milestone-edit', milestone)"
      @delete="$emit('milestone-delete', milestone)"
    />
  </div>
</div>
</template>

<script>
import Milestone from '@/components/builder/Milestone.vue';
import { startingFundsPercent } from '@/utils/objectUtils';

export default {
  name: 'timeline-milestones',
  components: {
    Milestone,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  computed: {
    receiveFundsPercent() {
      return startingFundsPercent(this.project.milestones);
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.timeline-milestones {
  margin-bottom: 32px;
  .timeline-milestone {
    display: flex;
    flex-direction: row;
    .bullet-wrap {
      position: relative;

      .bullet-line {
        width: 10px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -31px;
        border-left: 1px solid $border-light;

        &.first {
          top: 40px;
        }
        &.last {
          bottom: calc(100% - 35px);
        }
        &.only {
          display: none;
        }
      }

      .bullet {
        position: absolute;
        top: 20px;
        left: -40px;
      }
    }
    .milestone-info {
      margin-top: 24px;
      .desc {
        box-sizing: border-box;
        padding-right: 48px;
      }
    }
    &.fixed .milestone-info {
      border: 1px solid $white;
      padding-top: 0;
      margin-top: 20px;
      cursor: default;
      hr {
        display: none;
      }
    }
  }
}
</style>
