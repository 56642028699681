import { getCreatorProjectDetail, updateProject } from '@/utils/api';
import { fieldErrors } from '@/utils/apiError';
import { prepareProject } from '@/utils/objectUtils';
import formMixin from '@/mixins/Form';
import AutoSave from './AutoSave';

export default {
  data() {
    return {
      dataValue: null,
      updateFields: {},
      errorFields: {},
    };
  },
  mixins: [AutoSave, formMixin],
  methods: {
    updated(field) {
      if(this.validateField(field)) {
        let val = this.dataValue[field];
        // TODO -- handle this in validation?
        if(field === 'description' || field === 'blurb') {
          val = { en: val };
        }
        if(field === 'images') {
          val = { locale: 'en', id: val.map(v => v.id) };
        }
        this.updateFields[field] = val;
        this.startSaveTimer();
      } else {
        delete this.updateFields[field];
      }
    },
    update(field, value) {
      this.$set(this.dataValue, field, value);
      this.updated(field);
    },
    async executeSave() {
      const projectId = this.dataValue.id;
      try {
        const projectData = prepareProject(this.updateFields);
        await updateProject({ projectId, projectData });
      } catch(err) {
        this.errors = { ...(this.errors || {}), ...fieldErrors(err.errors) };
      }
      this.updateSaveStatus(true);
      this.updateFields = {};
      this.removeSaveTimer();
    },
  },
  async created() {
    // Pending update data is non-reactive.
    this.updateFields = {};
    this.dataValue = await getCreatorProjectDetail(this.$route.params.id);
  },
};
